
import { refuteActionTypes } from './refute.actiontype';

const initalState = {
  all: {
    pageSize: 10,
    pageNo: 1,
    search: ''
  },
  exclusive: {
    pageSize: 10,
    pageNo: 1,
    search: ''
  },
  accepted: {
    pageSize: 10,
    pageNo: 1,
    search: ''
  },
  rejected: {
    pageSize: 10,
    pageNo: 1,
    search: ''
  },
  pending: {
    pageSize: 10,
    pageNo: 1,
    search: ''
  },
}

export const Refute = (state = initalState, action = {}) => {
  switch (action.type) {
    case refuteActionTypes.SET_PAGE:
      return Object.assign({}, state, {
        ...state,
        [action.data.name]: {
          ...state[action.data.name],
          pageSize: action.data.value.pageSize,
          pageNo: action.data.value.pageNo,
          search: action.data.value?.search || ''
        }
      });

    case refuteActionTypes.SET_INIT:
      return initalState;
    default:
      return state;
  }
}