import React, { Component } from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import clsx from 'clsx';
import {authService} from '../../service/api/auth.service'
// import * as auth from "../../store/ducks/auth.duck";
// import { requestPassword } from "../../crud/auth.crud";

class ForgotPassword extends Component {
  state = {
    isRequested: false,
    apiProcess: false,
    apiError: '',
    apiSuccess: false
  };

  render() {
    const { apiError, apiSuccess, apiProcess } = this.state;

    return (
      <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
        <div className="kt-login__body loginForm">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3 className="font-size-h1 title">
              Forgotten Password?
              </h3>
            </div>
            {
              apiError &&
              (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{apiError}</div>
                </div>
              )
            }
            {
              apiSuccess &&
              (
                <div role="alert" className="alert alert-info">
                  <div className="alert-text">Password Reset link mailed</div>
                </div>
              )
            }
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ email: "" }}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  errors.email = 'Email ID is required to reset the password.';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Email ID is not valid';
                }

                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                this.setState({apiProcess: true});
                try {
                  await authService.forgotPass_sendEmail(values.email);
                  this.setState({apiSuccess: true, apiError: ''});
                }catch(error) {
                  const {data:{status:{message}}} = error
                  this.setState({apiError: message});
                }
                this.setState({apiProcess: false});
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className="kt-form">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Email *"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.email})}`}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.email}</div>
                    </div>
                  </div>

                  <div className="kt-login__actions centerActions">
                    <Link to="/auth">
                      <button
                        type="button"
                        className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                      >
                        Login
                      </button>
                    </Link>

                    <button
                      type="submit"
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": apiProcess
                        }
                      )}`}
                      disabled={isSubmitting}
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
