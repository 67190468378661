import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { change_pageHeading } from "../store/appStore/appStore.action";
import { checkModuleCRUDstatus } from "../store/modules_mallsStore/mm.actions";
// import Overall from "./DashboardNew/Overall";
// import Earn from "./DashboardNew/Earn";
// import Loyalty from "./DashboardNew/Loyalty";
// import Tier from "./DashboardNew/Tier";
import { PageName } from "../utils/pageTitles";
import CommunicationRoutes from './communicationRoutes';
import ContentRoutes from './contentRoutes';
import LoyaltyRoutes from './loyaltyRoutes';
import ReportsRoute from './ReportsRoute';
import ConciergeRoutes from './conciergeRoutes';
import withAllowed from "../utils/withAllowed";
// import MallSwitchModal from '../component/mallSwitchModal';

// import '../style/customs.scss';
// import UserManagement from './UserManagement';

const AccessDenied = lazy(() => import("./Access/accessDenied"));

const Retailer = lazy(() => import("./retailer/index"));

const UserManagement = lazy(() => import("./UserManagement"));

const CustomerProfile = lazy(() => import("./CustomerManagement"));

// const UserManagement = lazy(() => import('./UserManagement'));
const BookingManagement = lazy(() => import("./BookingManagement/index"));

const ProfilePage = lazy(() => import("./profile"));

const Parking = lazy(() => import("./Parking"));

const BaggageService = lazy(() => import("./BaggageService/index"));

const Dineout = lazy(() => import("./Dineout/index"));

const HandlerApp = lazy(() => import("./HandlerApp/index"));


// const CouponRedemptionReportView = lazy(() =>
//   import('./Reports/CouponRedemptionReportView')
// );

// const CouponRedemptionReportViewUsers = lazy(() =>
//   import('./Reports/CouponRedemptionReportViewUsers')
// );

// const CustomerWiseCouponReport = lazy(() =>
//   import('./Reports/CustomerWiseCouponReport')
// );


const FavStoreList = lazy(() => import("./Reports/FavStoreReport"));

const CustomersByDOB = lazy(() => import("./Reports/CustomersByDob"));

const CustomersByAnniversary = lazy(() =>
  import("./Reports/CustomersByAnniversary")
);


const IssueManagement = lazy(() => import("./IssueManagement/index"));

const FootfallReport = lazy(() => import("./FootfallReport/index"));

const PersonalisedMallVisit = lazy(() =>
  import("./PersonalisedMallVisit/index")
);



const VoucherManagement = lazy(() => import("./VoucherManagement/index"));

const OldDashboard = lazy(() => import("./DashboardPage"));

const NewDashboard = lazy(() => import("./DashboardNew/"));

const StaticPages = lazy(() => import("./StaticPages"));

const DefaultPage = ({ mallModalOpen, changePageHeading }) => {
  const appConstant = useSelector(
    ({ appData: { globalConstants } }) => globalConstants
  );
  const modulesList = useSelector(
    ({ modulesAndMalls: { modulesList } }) => modulesList
  );
  const renderComponentFP = (
    Component,
    pageTitle = { title: "", moduleKey: "" }
  ) => {
    return (props) => {
      changePageHeading(pageTitle.title);
      checkModuleCRUDstatus(pageTitle.moduleKey);
      return <Component {...props} />;
    };
  };


  return appConstant && modulesList ? (
    <Suspense>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/new/overview" />
        }

        <Route
          path="/old-dashboard"
          render={(props) =>
            renderComponentFP(OldDashboard, {
              title: "old dashboard",
              moduleKey: "",
            })(props)
          }
        />
        <Route
          path="/communication"
          render={(props) =>
            renderComponentFP(CommunicationRoutes, PageName.COMMUNICATION)(props)
          }
        />
        <Route
          path="/content"
          render={(props) =>
            renderComponentFP(ContentRoutes, PageName.COMMUNICATION)(props)
          }
        />
        <Route
          path="/loyalty"
          render={(props) =>
            renderComponentFP(LoyaltyRoutes, PageName.COMMUNICATION)(props)
          }
        />
        <Route
          path="/reports"
          render={(props) =>
            renderComponentFP(ReportsRoute, PageName.COMMUNICATION)(props)
          }
        />
        <Route
          path="/concierge"
          render={(props) =>
            renderComponentFP(ConciergeRoutes, PageName.COMMUNICATION)(props)
          }
        />

        <Route
          path="/new/overview"
          render={(props) => (
            <NewDashboard
              {...props}
              renderComponent={(title, Component, childProps) =>
                renderComponentFP(Component, {
                  title,
                  moduleKey: "",
                })(childProps)
              }
            />
          )}
        />

        <Route
          path="/retailers"
          render={(props) =>
            renderComponentFP(withAllowed(Retailer), PageName.RETAILER)(props)
          }
        />
        <Route
          path="/user-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(UserManagement),
              PageName.USERMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/customer-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomerProfile),
              PageName.CUSTOMERS
            )(props)
          }
        />
        <Route
          path="/booking-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(BookingManagement),
              PageName.BOOKINGMANAGEMENT
            )(props)
          }
        />
        <Route path="/profile" component={ProfilePage} />
        <Route
          path="/parking"
          render={(props) =>
            renderComponentFP(withAllowed(Parking), PageName.PARKING)(props)
          }
        />
        <Route
          path="/baggage-services"
          render={(props) =>
            renderComponentFP(
              withAllowed(BaggageService),
              PageName.BAGGAGESERVICES
            )(props)
          }
        />
        <Route
          path="/dineout"
          render={(props) =>
            renderComponentFP(withAllowed(Dineout), PageName.DINEOUT)(props)
          }
        />
        <Route
          path="/footfall-report"
          render={(props) =>
            renderComponentFP(FootfallReport, PageName.FOOTFALLREPORT)(props)
          }
        />
        {/* Report Starts */}

        {/* <Route path='/coupon-redemption/view/:id/details' render={props => renderComponentFP(CouponRedemptionReportView,PageName.COUPON_REDEMPTION_REPORT)(props)} />
        <Route path='/coupon-redemption/view/:id/users' render={props => renderComponentFP(CouponRedemptionReportViewUsers,PageName.COUPON_REDEMPTION_REPORT)(props)} />
        <Route path='/customer-wise-coupon-report' render={props => renderComponentFP(CustomerWiseCouponReport,PageName.COUPON_REDEMPTION_REPORT)(props)} /> */}

        {/* <Route path='/reward-redemption/view/:id/details' render={props => renderComponentFP(RewardRedemptionReportView,PageName.REWARD_REDEMPTION_REPORT)(props)} />
        <Route path='/reward-redemption/view/:id/users' render={props => renderComponentFP(RewardRedemptionReportViewUsers,PageName.REWARD_REDEMPTION_REPORT)(props)} />
        <Route path='/customer-wise-reward-report' render={props => renderComponentFP(CustomerWiseRewardReport,PageName.REWARD_REDEMPTION_REPORT)(props)} /> */}
        <Route
          path="/fav-store-report-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(FavStoreList),
              PageName.FAVORITESTOREREPORT
            )(props)
          }
        />

        <Route
          path="/customers-by-dob-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomersByDOB),
              PageName.CUSTOMERBYDOB
            )(props)
          }
        />

        <Route
          path="/customers-by-anniversary-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomersByAnniversary),
              PageName.CUSTOMERBYANIVVERSARY
            )(props)
          }
        />
        {/* Report Ends */}
        <Route
          path="/voucher"
          render={(props) =>
            renderComponentFP(
              VoucherManagement,
              PageName.VOUCHERMANAGEMENTBACKEND
            )(props)
          }
        />
        <Route
          path="/issue-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(IssueManagement),
              PageName.ISSUEMANAGEMENT
            )(props)
          }
        />


        <Route
          path="/personalised-plan-visit"
          render={(props) =>
            renderComponentFP(
              withAllowed(PersonalisedMallVisit),
              PageName.PERSONALISEDMALLVISIT
            )(props)
          }
        />
        <Route
          path="/handler"
          render={(props) =>
            renderComponentFP(
              withAllowed(HandlerApp),
              PageName.HANDERLARAPP
            )(props)
          }
        />


        <Route
          path="/voucher-management"
          render={(props) =>
            renderComponentFP(
              VoucherManagement,
              PageName.VOUCHERMANAGEMENTBACKEND
            )(props)
          }
        />

        <Route
          path="/frontdesk-voucher-management"
          render={(props) =>
            renderComponentFP(
              VoucherManagement,
              PageName.VOUCHERMANAGEMENTBACKEND
            )(props)
          }
        />


        <Route
          path="/static-pages"
          render={(props) =>
            renderComponentFP(StaticPages, PageName.STATIC_PAGES)(props)
          }
        />

        <Route path="/access-denied" component={AccessDenied} />

        {/* <Route path='/customer-management' component={CustomerProfile} />
        <Route path='/booking-management' component={BookingManagement} /> */}
      </Switch>
      {/* <MallSwitchModal isOpen={mallModalOpen} toggle={toogleMallModal}/> */}
    </Suspense>
  ) : (
    <LayoutSplashScreen visible={true} />
  );
};

const mapStateToProps = ({ appData }) => ({
  mallModalOpen: appData.mallSwitchOpen,
});
const mapDisptachToProps = (dispatch) => ({
  changePageHeading: (pageHeading) => dispatch(change_pageHeading(pageHeading)),
  checkModuleCRUDstatus: (moduleName) =>
    dispatch(checkModuleCRUDstatus(moduleName)),
});
export default connect(mapStateToProps, mapDisptachToProps)(DefaultPage);
