import {HTTPClient} from './http.service';
var qs = require('qs');

export const parkingManagement = {
    async addParking(data) {
		try {
			let result = HTTPClient.customRequest({
				url: `/parking`,
				method: 'post',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
	async getConfig(params){
		try {
			let result = await HTTPClient.get(`/parking`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async getBookings(params) {
		try {
			let result = await HTTPClient.get(`/parking/getBookings/`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getSlots(){
		try {
			let result = await HTTPClient.get(`/parking/getAvailableSlots`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async deactivateSlots(data){
		try {
			let result = HTTPClient.customRequest({
				url: `/parking/deactivateSlot`,
				method: 'post',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			  });
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},

	async markLeftSingle(id){
		try {
			let result = HTTPClient.customRequest({
				url: `parking/markLeftSingle/${id}`,
				method: 'put',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			  });
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},

	
	async markLeftBulkDateWise(data){
		try {
			let result = HTTPClient.customRequest({
				url: `parking/markLeft`,
				method: 'put',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data:qs.stringify(data)
			  });
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
}