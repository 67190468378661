/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import {useLocation} from "react-router";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import MenuList from '../../../../../app/component/asideMenu/asideMenuList';
import { menuActionType } from '../../../../../app/store/menuSearch/menu.actionType';

// menuActionType.MENU_SEARCH

const AsideMenuList = (props) => {

  const { layoutProps } = props;
  const dispatch = useDispatch();

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";    
  };

  const searchMenu = debounce((value) => {
    dispatch({ type: menuActionType.MENU_SEARCH, data: value})
  }, 500)

  return (
      <>
        {/* begin::Menu Nav */}
        <form>
          <div className="d-flex">
            <div className="input-icon h-40px" style={{ width: "100%" }}>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid h-40px"
                placeholder="Search..."
                id="generalSearch"
                autoComplete="false"
                onChange={(e) => searchMenu(e.target.value)}
              />
              <span>
                <span className="svg-icon svg-icon-lg">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                  />
                </span>
              </span>
            </div>
          </div>
        </form>
        <MenuList layoutProps={layoutProps} getMenuItemActive={getMenuItemActive} />
        {/* end::Menu Nav */}
      </>
  );
}

export default AsideMenuList;
