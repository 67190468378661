import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector, createSelector } from "reselect";
import { appMenu } from "../store/modules_mallsStore/mm.select";
import { menuUrlMapping, urlsMappings } from "./menuUrlMapping";

function isEditable(state, urlKey) {
  const appMenu = state.modulesAndMalls.menuObj;
  const mappingItem = menuUrlMapping[urlKey];
  if (mappingItem) {
    const { menu, subMenu } = mappingItem;
    return (
      appMenu[menu] === 2 ||
      (subMenu && appMenu[menu] && appMenu[menu].subMenu[subMenu] === 2)
    );
  }
  return false;
}

export default function withAllowed(Component) {
  const WrappedComponent = class extends React.Component {
    render() {

      const {
        match: { path },
        appMenu,
      } = this.props;
      const mappingItem = menuUrlMapping[path];
      if (mappingItem) {
        const { menu, subMenu } = mappingItem;
        if (
          appMenu[menu] === 1 ||
          appMenu[menu] === 2 ||
          (subMenu &&
            appMenu[menu] &&
            (appMenu[menu].subMenu[subMenu] === 1 ||
              appMenu[menu].subMenu[subMenu] === 2))
        ) {
          return <Component {...this.props} {...this.state} />;
        }
      }
      return <Redirect to="/access-denied" />;
    }
  };

  return connect(
    createStructuredSelector({
      appMenu,
    })
  )(WrappedComponent);
}

function isNotificationEditable(state) {
  return isEditable(state, urlsMappings.notifications);
}

function isBannerEditable(state) {
  return isEditable(state, urlsMappings.banner);
}

function isOnboardingScreenEditable(state) {
  return isEditable(state, urlsMappings.onboardingScreens);
}

function isMallFeedManagerEditable(state) {
  return isEditable(state, urlsMappings.mallFeedManagers);
}

function isBookingEditable(state) {
  return isEditable(state, urlsMappings.bookingManagement);
}

function isDeepLinkEditable(state) {
  return isEditable(state, urlsMappings.deepLink);
}

function isUserManagementEditable(state) {
  return isEditable(state, urlsMappings.userManagement);
}

function isRetailerManagementEditable(state) {
  return isEditable(state, urlsMappings.retailers);
}

function isReferralCampaignEditable(state) {
  return isEditable(state, urlsMappings.referral);
}

function isCustomerManagementEditable(state) {
  return isEditable(state, urlsMappings.customerManagement);
}

function isRefuteManagementEditable(state) {
  return isEditable(state, urlsMappings.refuteManagement);
}

function isCouponManagementEditable(state) {
  return isEditable(state, urlsMappings.couponManagement);
}

function isCouponManagementFullEditable(state) {
  return isEditable(state, urlsMappings.couponManagementFull);
}

function isScratchCardManagementEditable(state) {
  return isEditable(state, urlsMappings.scratchCardManagement);
}

function isRuleEngineEditable(state) {
  return isEditable(state, urlsMappings.ruleEngine);
}

function isRewardManagementEditable(state) {
  return isEditable(state, urlsMappings.rewardManagement);
}

function isShopAndWinEditable(state) {
  return isEditable(state, urlsMappings.shopAndWin);
}

function isSpinTheWheelEditable(state) {
  return isEditable(state, urlsMappings.spinTheWheel);
}

function isParkingEditable(state) {
  return isEditable(state, urlsMappings.parking);
}
function isBaggageServiceEditable(state) {
  return isEditable(state, urlsMappings.baggageServices);
}

function isScanReportEditable(state) {
  return isEditable(state, urlsMappings.scanReports);
}

function isConciergeIssueEditable(state) {
  return isEditable(state, urlsMappings.otherIssues);
}

function isConciergeScanServiceEditable(state) {
  return isEditable(state, urlsMappings.scanService);
}

function isConciergeCouponVoucherManagementEditable(state) {
  return isEditable(
    state,
    urlsMappings.conciergePhysicalDistributionCouponVouchers
  );
}

function isConciergeRewardVoucherManagementEditable(state) {
  return isEditable(
    state,
    urlsMappings.conciergePhysicalDistributionRewardVouchers
  );
}

function isVoucherMgmtInwardEditable(state) {
  return true;
  // return isEditable(state, urlsMappings.voucherMgmt.inward);
}

function isVoucherMgmtOutwardEditable(state) {
  return true;

  // return isEditable(state, urlsMappings.voucherMgmt.outward);
}
function isVoucherMgmtRetailersEditable(state) {
  return true;

  // return isEditable(state, urlsMappings.voucherMgmt.retailers);
}

function isFrontdeskMgmtOutwardEditable(state) {
  return true;

  // return isEditable(state, urlsMappings.frontdeskMgmt.outward);
}

function isCustomerDelightEditable(state) {
  return isEditable(state, urlsMappings.customerDelight);
}

export {
  urlsMappings,
  isEditable,
  isNotificationEditable,
  isBannerEditable,
  isOnboardingScreenEditable,
  isMallFeedManagerEditable,
  isDeepLinkEditable,
  isBookingEditable,
  isUserManagementEditable,
  isRetailerManagementEditable,
  isReferralCampaignEditable,
  isCustomerManagementEditable,
  isRefuteManagementEditable,
  isCouponManagementEditable,
  isCouponManagementFullEditable,
  isScratchCardManagementEditable,
  isRuleEngineEditable,
  isRewardManagementEditable,
  isShopAndWinEditable,
  isSpinTheWheelEditable,
  isParkingEditable,
  isBaggageServiceEditable,
  isScanReportEditable,
  isConciergeIssueEditable,
  isConciergeScanServiceEditable,
  isConciergeCouponVoucherManagementEditable,
  isConciergeRewardVoucherManagementEditable,
  isVoucherMgmtInwardEditable,
  isVoucherMgmtOutwardEditable,
  isVoucherMgmtRetailersEditable,
  isFrontdeskMgmtOutwardEditable,
  isCustomerDelightEditable,
};
