import {mmActiontypes} from './mm.actionTypes';

export const modules_init = () => ({
  type: mmActiontypes.MODULES_INIT
});

export const modules_success = (modulesObj) => ({
  type: mmActiontypes.MODULES_SUCCESS,
  payload: modulesObj
});

export const modules_mallInitalData = (userData) => ({
  type: mmActiontypes.MM_INITALDATA,
  payload: userData // we will extract what is required
});

export const malls_switch_toggle = () => ({
  type: mmActiontypes.MALL_SWITCH_TOGGLE
});

export const malls_switch_init = (mallId) => ({
  type: mmActiontypes.MALL_SWITCH_INIT,
  payload: mallId
});

export const malls_switch_success = () => ({
  type: mmActiontypes.MALL_SWITCH_SUCCESS
});

export const modules_mallFails = (errMsg) => ({
  type: mmActiontypes.MM_ERROR,
  payload: errMsg
});

export const modules_mallerrorClr = (errMsg) => ({
  type: mmActiontypes.MM_ERROR_CLEAR,
  payload: errMsg
});

export const checkModuleCRUDstatus = (pageName) => ({
  type: mmActiontypes.MM_CRUD_STATUS,
  payload: pageName,
})

export const logoutAction = () => ({
  type: mmActiontypes.LOGOUT
})