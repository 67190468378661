import {HTTPClient} from './http.service';

export const rewardService = {
  async getRetailers(param) {
    try {
      const {data:{data:retailerList}} = await HTTPClient.get('/retailers/', param);
      return retailerList;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getRetailerDetails(id) {
    try {
      const {data:{data: retailerInfo}} = await HTTPClient.get(`/retailers/${id}`);
      return retailerInfo;
      // return retailerList;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getRewardList(param) {
    try {
      const response = await HTTPClient.get('/rewards/', param);
      return response.data.data;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async addReward(data) {
    try {
      const result = await HTTPClient.post('/rewards/', data);
      return result;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async addRewardCode(data) {
    try {
      const result = await HTTPClient.post('/rewards/codes', data);
      return result;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async updateRewardCode (data, id) {
    try {
      const result = await HTTPClient.put(`/rewards/${id}/codes`, data);
      return result;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getRewardData(id) {
    try {
      const {data: {data}} = await HTTPClient.get(`/rewards/${id}`);
      return data;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async uopdateRewardData(id, data) {
    try {
      const response = await HTTPClient.put(`/rewards/${id}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async deleteReward(id, data) {
    try {
      const response = await HTTPClient.deleteWithBody(`/rewards/${id}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async rewardManagementCohortList() {
    try {
      const response = await HTTPClient.get('/rewards/cohort');
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async getUsersList (id, data) {
    try {
      const response = await HTTPClient.get(`/rewards/rewardUsage/${id}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  }
}