import {HTTPClient} from './http.service';

export const personalisedPlanVisit = {
  async getVisitList(param) {
    try {
      const data = await HTTPClient.get('/personalizedMallVisit/getSets', param);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getVisitDetails(id) {
    try {
      const data = await HTTPClient.get(`/personalizedMallVisit/getSets/${id}`);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  }
}