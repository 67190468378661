import React, { Component } from "react";
import {connect} from 'react-redux';
import { Redirect } from "react-router-dom";
import {logoutStart} from '../../store/auth/auth.action';
import { LayoutSplashScreen } from "../../../_metronic/layout";
// import * as auth from "../../store/ducks/auth.duck";
// import { connect } from "react-redux";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}

const mapStateToProps = ({auth}) => ({
  hasAuthToken: Boolean(auth.authToken),
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutStart())
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
