import {HTTPClient} from './http.service';

export const couponService = {
  async getRetailers(param) {
    try {
      const {data:{data:retailerList}} = await HTTPClient.get('/retailers/', param);
      return retailerList;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getRetailerDetails(id) {
    try {
      const {data:{data: retailerInfo}} = await HTTPClient.get(`/retailers/${id}`);
      return retailerInfo;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getCouponList(param) {
    try {
      const {data:{data:couponList}} = await HTTPClient.get('/coupon/', param);
      return couponList;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async addCoupon(data) {
    try {
      const {data:{data:couponCode}} = await HTTPClient.post('/coupon', data);
      return couponCode;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async addCouponPro(data) {
    try {
      const {data:{data:couponCode}} = await HTTPClient.post('/coupon/addCouponPro', data);
      return couponCode;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async addCouponCode(data) {
    try {
      const {data:{data:couponCode}} = await HTTPClient.post('/coupon/codes', data);
      return couponCode;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async updateCouponCode(data, id) {
    try {
      const {data:{data:response}} = await HTTPClient.put(`/coupon/updateCouponCode/${id}`, data);
      return response;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getCouponData(id) {
    try {
      const {data:{data: couponData}} = await HTTPClient.get(`/coupon/${id}`);
      return couponData;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async updateCouponData(id, data) {
    try {
      const response = await HTTPClient.put(`/coupon/${id}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async updateCouponProData(id, data) {
    try {
      const response = await HTTPClient.put(`/coupon/updateCouponPro/${id}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async getTierList() {
    try {
      const {data:{data : docs}} = await HTTPClient.get(`/tier`);
      return docs;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async deleteCoupon(id, status='delete') {
    try {
      const response = await HTTPClient.delete(`/coupon/${id}?status=${status}`);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },
  async getCouponUsage(id, params) {
    try {
      const response = await HTTPClient.get(`/coupon/getCouponUsage/${id}`, params);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },
}
// tier changed
