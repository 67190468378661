
import {retailerActionTypes} from './retailer.actiontype';

export const actions = {
  request: () => ({ type: retailerActionTypes.REQUEST }),
  complete: () => ({ type: retailerActionTypes.COMPLETE }),

  addRequest: () => ({type: retailerActionTypes.ADD_REQUEST}),

  getRetailers: data => ({type: retailerActionTypes.GET_RETAILERS, data}),
};