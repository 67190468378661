import {HTTPClient} from './http.service';
var qs = require('qs');

export const spinTheWheelService = {

  async getSpiWheels(data) {
    try {
      const result = await HTTPClient.get('/spinWheel/', data);
      return result;
    } catch(error) {
      return error;
    }
  },

  async addSpinTheWheel(data) {
  	try {
		let result = HTTPClient.customRequest({
			url: `/spinWheel`,
			method: 'post',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			data: qs.stringify(data)
		});
		return result;
	} catch (error) {
		let error_object = { error_type: 'SERVER' };
		if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
		else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
		else error_object.errors = 'UNKNOWN ERROR'
		throw error_object;
	}
  },

  async getSingleSpinTheWheelDetails(id) {
    try {
      const {data:{data}} = await HTTPClient.get(`/spinWheel/${id}`);
      return data;
    } catch(e) {
      return Promise.reject(e)
    }
  },

  async updateSpinTheWheel(id, data) {
  	try {
		let result = HTTPClient.customRequest({
			url: `/spinWheel/updateSpinWheel/${id}`,
			method: 'put',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			data: qs.stringify(data)
		});
		return result;
	} catch (error) {
		let error_object = { error_type: 'SERVER' };
		if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
		else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
		else error_object.errors = 'UNKNOWN ERROR'
		throw error_object;
	}
  },

  async updateCampaignStatus(id, data) {
	try {
	  let result = HTTPClient.customRequest({
		  url: `/spinWheel/${id}`,
		  method: 'put',
		  headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		  data: qs.stringify(data)
	  });
	  return result;
  } catch (error) {
	  let error_object = { error_type: 'SERVER' };
	  if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
	  else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
	  else error_object.errors = 'UNKNOWN ERROR'
	  throw error_object;
  }
},

async getCampaignUsage(id, data) {
    try {
      const response = await HTTPClient.get(`/spinWheel/spinUsage/${id}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },

}