
import {notificationActionTypes} from './notification.actiontype';

export const actions = {
  getCohorts: data => ({ type: notificationActionTypes.GET_COHORTS, data: data }),
  getTiers: data => ({ type: notificationActionTypes.GET_TIERS, data: data }),
  request: () => ({ type: notificationActionTypes.REQUEST }),
  complete: () => ({ type: notificationActionTypes.COMPLETE }),
  getNotifications: data => ({type: notificationActionTypes.GET_NOTIFICATIONS, data: data }),
  deleteNotification: id => ({type: notificationActionTypes.DELETE_NOTIFICATIONS, data: id }),
  successEditNotificaiton: data => ({type: notificationActionTypes.EDIT_SUCCESS, data }),
  getRedirectionTypeList: data => ({type: notificationActionTypes.GET_REDIRECITON_TYPE_LIST, data }),
  
};