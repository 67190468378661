import {customerActionType} from './customer.actionType';

const INIT_STATE = {
  customerDetails:{},
  pageInfo:{
    pageSize: 10,
    pageNo: 1
  },
}

const Customer = (state= INIT_STATE, {type, payload}) => {
  switch(type) {

    case customerActionType.CUSTOMER_INFO:
		return Object.assign({}, state, {
			customerDetails : payload
        });

    case customerActionType.CUSTOMER_SET_PAGE:
      return Object.assign({}, state, {
        ...state,
        pageInfo: {
          pageSize: payload.value.pageSize,
          pageNo: payload.value.pageNo
        }
      });

    case customerActionType.CUSTOMER_SET_INIT:
      return Object.assign({}, state, {
        ...state,
        pageInfo: {
          pageSize: 10,
          pageNo: 1,
        }
      });
        	
    default:
      return state
  }
}

export default Customer;