export const retailerActionTypes = {
  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',

  WATCH_GET_RETAILERS: 'WATCH_GET_RETAILERS',

  GET_RETAILERS: 'GET_RETAILERS',

  RETAILER_SET_PAGE: 'RETAILER_SET_PAGE',
  RETAILER_SET_INIT: 'RETAILER_SET_INIT',
};