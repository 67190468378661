import {HTTPClient} from './http.service';
var qs = require('qs');

export const ruleService = {
    async couponList () {
      try {
        const result = await HTTPClient.get('/coupon/?sort=desc&status=active&category=2');
        return result;
      } catch (error) {
        throw error;
      }
    },
    async rewardList () {
      try {
        const result = await HTTPClient.get('/rewards/?sort=desc&status=2&category=2');
        return result;
      } catch (error) {
        throw error;
      }
    },
    async addRule(data) {
      try {
        let result = HTTPClient.customRequest({
          url: `/rule/`,
          method: 'post',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          data: qs.stringify(data)
          });
        return result;
      } catch (error) {
        let error_object = { error_type: 'SERVER' };
        if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
        else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
        else error_object.errors = 'UNKNOWN ERROR'
        throw error_object;
      }
    },
    async updateRule(data, id) {
      try {
        let result = HTTPClient.customRequest({
          url: `/rule/${id}`,
          method: 'put',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          data: qs.stringify(data)
          });
        return result;
      } catch (error) {
        let error_object = { error_type: 'SERVER' };
        if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
        else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
        else error_object.errors = 'UNKNOWN ERROR'
        throw error_object;
      }
    },
    async deleteRule(id) {
      try {
        let result = await HTTPClient.delete(`/rule/${id}`);
        return result;
      } catch (error) {
        let error_object = { error_type: 'SERVER' };
        if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
        else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
        else error_object.errors = 'UNKNOWN ERROR'
        throw error_object;
      }
    },
    async viewRule(id) {
      try {
        let result = await HTTPClient.get(`/rule/${id}`);
        return result;
      } catch (error) {
        let error_object = { error_type: 'SERVER' };
        if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
        else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
        else error_object.errors = 'UNKNOWN ERROR'
        throw error_object;
      }
    },
    async ruleList(params) {
      try {
        let result = await HTTPClient.get(`/rule/`, params);
        return result;
      } catch (error) {
        let error_object = { error_type: 'SERVER' };
        if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
        else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
        else error_object.errors = 'UNKNOWN ERROR'
        throw error_object;
      }
    },
    async userList(userId, params) {
      try {
        let result = await HTTPClient.get(`/rule/users/${userId}`, params);
        return result;
      } catch (error) {
        let error_object = { error_type: 'SERVER' };
        if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
        else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
        else error_object.errors = 'UNKNOWN ERROR'
        throw error_object;
      }
    },
}
