import {HTTPClient} from './http.service';

export const dineoutService = {
  async getDineoutOrders(param) {
    try {
      const data = await HTTPClient.get('/dineout/', param);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  }
}