import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { change_pageHeading } from "../store/appStore/appStore.action";
import { checkModuleCRUDstatus } from "../store/modules_mallsStore/mm.actions";
import { PageName } from "../utils/pageTitles";
import withAllowed from "../utils/withAllowed";

const CouponManagementLite = lazy(() => import("./couponManagement"));
const CouponManagementFull = lazy(() => import("./couponManagementFull"));
const RefuteManagement = lazy(() => import("./refuteManagement"));
const ScanReconcile = lazy(() => import("./ScanReconcile"));
const RewardManagement = lazy(() => import("./rewardManagement/index"));
const CustomerDelight = lazy(() => import("./CustomerDelight/index"));
const ShopAndWin = lazy(() => import("./ShopAndWin/index"));
const RuleEngine = lazy(() => import("./RuleEngine"));
const ScratchCardManagement = lazy(() =>
  import("./ScratchCardManagement/index")
);
const SpinTheWheel = lazy(() => import("./SpinTheWheel"));


const LoyaltyRoutes = ({ changePageHeading, match, ...props }) => {
  const {path, url} = match;
  const renderComponentFP = (
    Component,
    pageTitle = { title: "", moduleKey: "" }
  ) => {
    return (props) => {
      changePageHeading(pageTitle.title);
      checkModuleCRUDstatus(pageTitle.moduleKey);
      return <Component {...props} />;
    };
  };
  return <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from={url} to={`${url}/loyalty`} />
        }

        <Route
          path="/loyalty/coupon-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(CouponManagementLite),
              PageName.COUPONLITE
            )(props)
          }
        />
        <Route
          path="/loyalty/coupon-management-full"
          render={(props) =>
            renderComponentFP(
              withAllowed(CouponManagementFull),
              PageName.COUPONPRO
            )(props)
          }
        />
        <Route
          path="/loyalty/scratch-card-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(ScratchCardManagement),
              PageName.SCRATCHCARDMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/loyalty/rule-engine"
          render={(props) =>
            renderComponentFP(
              withAllowed(RuleEngine),
              PageName.RULEENGINER
            )(props)
          }
        />
        <Route
          path="/loyalty/reward-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(RewardManagement),
              PageName.REWARDMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/loyalty/refute-management"
          render={(props) =>
            renderComponentFP(
              withAllowed(RefuteManagement),
              PageName.REFUTEMANAGEMENT
            )(props)
          }
        />
        <Route
          path="/loyalty/scan-reconcile"
          render={(props) =>
            renderComponentFP(
              // withAllowed(ScanReconcile),
              ScanReconcile,
              PageName.SCANRECONCILE
            )(props)
          }
        />
        <Route
          path="/loyalty/customer-delight"
          render={(props) =>
            renderComponentFP(
              withAllowed(CustomerDelight),
              PageName.CUSTOMERDELIGHT
            )(props)
          }
        />
        <Route
          path="/loyalty/shop-and-win"
          render={(props) =>
            renderComponentFP(
              withAllowed(ShopAndWin),
              PageName.SHOPANDWIN
            )(props)
          }
        />
        <Route
          path="/loyalty/spin-the-wheel"
          render={(props) =>
            renderComponentFP(
              withAllowed(SpinTheWheel),
              PageName.SPINWHEEL
            )(props)
          }
        />
    </Switch>

};

const mapStateToProps = ({ appData }) => ({
  mallModalOpen: appData.mallSwitchOpen,
});
const mapDisptachToProps = (dispatch) => ({
  changePageHeading: (pageHeading) => dispatch(change_pageHeading(pageHeading)),
  checkModuleCRUDstatus: (moduleName) =>
    dispatch(checkModuleCRUDstatus(moduleName)),
});
export default connect(mapStateToProps, mapDisptachToProps)(LoyaltyRoutes);
