import {HTTPClient} from './http.service';

export const notificationService = {
  async cohortList() {
    try {
      const result = await HTTPClient.get('/notification/cohort');
      return result;
    } catch(error) {
      return error;
    }
  },
  async tierList() {
    try {
      const result = await HTTPClient.get('/tiers');
      return result;
    } catch(error) {
      return error;
    }
  },
  async addNotification (data) {
    try {
      const result = await HTTPClient.post('/notification/', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getNotifications (data) {
    try {
      const result = await HTTPClient.get('/notification/', data);
      return result;
    } catch(error) {
      return error;
    }
  },
  async deleteNotification (data) {
    try {
      const result = await HTTPClient.delete(`/notification/${data}/`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getNotificationById (data) {
    try {
      const result = await HTTPClient.get(`/notification/${data}`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async editNotification (data, id) {
    try {
      const result = await HTTPClient.put(`/notification/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async searchUsers (data) {
    try {
      const result = await HTTPClient.get(`/notification/user?pattern=${data}`);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getNotificationRedirectionList () {
    try {
      const result = await HTTPClient.get('/notification/redirectionType');
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
}
