
import {shopNumberActionTypes} from './shopNumber.actiontype';

export const actions = {
  request: () => ({ type: shopNumberActionTypes.REQUEST }),
  complete: () => ({ type: shopNumberActionTypes.COMPLETE }),

  addRequest: () => ({type: shopNumberActionTypes.ADD_REQUEST}),
  addComplete: () => ({type: shopNumberActionTypes.ADD_COMPLETE}),
  addSuccess: (data) => ({type: shopNumberActionTypes.ADD_SUCCESS, data}),

  getShopNumbers: data => ({ type: shopNumberActionTypes.GET_SHOP_NUMBERS, data: data }),
  clearShopNumbers: () => ({ type: shopNumberActionTypes.CLEAR_SHOP_NUMBERS }),
  getShopNumberById: data => ({ type: shopNumberActionTypes.GET_SHOP_NUMBER_BY_ID, data }),
  deleteShopNumber: id => ({ type: shopNumberActionTypes.DELETE_SHOP_NUMBER_BY_ID, data: id }),
  updateShopNumber: data => ({ type: shopNumberActionTypes.UPDATE_SHOP_NUMBER, data: data }),

  setError: data => ({ type: shopNumberActionTypes.SET_ERROR, data }),
};