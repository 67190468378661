export const categoryActionTypes = {
  WATCH_ADD_RETAILER_CATEOGORY: 'WATCH_ADD_RETAILER_CATEOGORY',
  WATCH_GET_RETAILER_CATEOGORIES: 'WATCH_GET_RETAILER_CATEOGORIES',
  WATCH_GET_RETAILER_CATEGORY_BY_ID: 'WATCH_GET_RETAILER_CATEGORY_BY_ID',
  WATCH_UPDATE_RETAILER_CATEOGORY: 'WATCH_UPDATE_RETAILER_CATEOGORY',
  WATCH_DELETE_RETAILER_CATEOGORY: 'WATCH_DELETE_RETAILER_CATEOGORY',

  SET_MODAL_SHOW: 'SET_MODAL_SHOW',


  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORY_BY_ID: 'GET_CATEGORY_BY_ID',
  DELETE_CATEGORY_BY_ID: 'DELETE_CATEGORY_BY_ID',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',

  ADD_REQUEST: 'ADD_REQUEST',
  ADD_COMPLETE: 'ADD_COMPLETE',

  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',
  ADD_FAILED: 'ADD_FAILED',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  CLEAR_INITIAL: 'CLEAR_INITIAL',

  SET_ERROR: 'SET_ERROR',
  ADD_SUCCESS: 'ADD_SUCCESS'
};