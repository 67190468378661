import {HTTPClient} from './http.service';
var qs = require('qs');

export const bulkTransferService = {

  async getCustomerPhysicalVoucherList(customerMobile, params) {
      try {
        const result = await HTTPClient.get(`/bulkVoucherTransfer/${customerMobile}`, params);
        return result;
      } catch(error) {
        return error;
      }
  },
  async requestOTP(data) {
      try {
        let result = HTTPClient.customRequest({
            url: `/bulkVoucherTransfer/verifyUser/requestOtp`,
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data)
        });
        return result;
    } catch (error) {
        let error_object = { error_type: 'SERVER' };
        if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
        else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
        else error_object.errors = 'UNKNOWN ERROR'
        throw error_object;
    }
  },
  async bulkTransfer(data) {
    try {
      const result = await HTTPClient.post(`/bulkVoucherTransfer`, data);
      return result;
    } catch (error) {
      return error;
    }
  },

  async getTransactionList(params) {
    try {
      const result = await HTTPClient.get(`/bulkVoucherTransfer/transactions`, params);
      return result;
    } catch(error) {
      return error;
    }
  },

  async getTransactionDetail(id, params) {
    try {
      const result = await HTTPClient.get(`/bulkVoucherTransfer/transactions/${id}`, params);
      return result;
    } catch(error) {
      return error;
    }
  }
} 