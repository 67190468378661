import { takeLatest, put, all, call } from 'redux-saga/effects';
import { shopNumberActionTypes } from './shopNumber.actiontype';
import { retailerShopNumberService } from '../../../service';
import { actions } from './shopNumber.action';

function* handleAddShopNumber ({data}) {
  try {
    yield put(actions.addRequest())
    let result = yield retailerShopNumberService.addShopNumber(data)
    let message = 'Shop number added successfully'
    yield put(actions.addSuccess(message))
    return result
  } catch (error) {
    yield put(actions.setError(error))
  } finally {
    yield put(actions.addComplete())
  }
}

function* handleGetShopNumbers ({data}) {
  try {
    yield put(actions.request())
    let result = yield retailerShopNumberService.getShopNumbers(data);
    yield put(actions.getShopNumbers(result.data.data))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(actions.complete())
  }
}

function* handleClearShopNumbers(params) {
  yield put(actions.clearShopNumbers())
}

function* handleGetShopNumberById ({id}) {
  try {
    let result = yield retailerShopNumberService.getShopNumberById(id);
    yield put(actions.getShopNumberById(result.data.data))
    return result
  } catch (error) {
    console.log(error)
  }
}

function* handleUpdateShopNumber ({data, id, temp}) {
  try {
    let result = yield retailerShopNumberService.updateShopNumber(data, id);
    yield put(actions.updateShopNumber({...temp, id}))
    return result
  } catch (error) {
    console.log(error)
  }
}

function* handleDeleteShopNumber ({id}) {
  try {
    let result = yield retailerShopNumberService.deleteShopNumber(id);
    yield put(actions.deleteShopNumber(id))
    return result
  } catch (error) {
    console.log(error)
  }
}


export function* addRetailerShopNumber() {
  yield takeLatest(shopNumberActionTypes.WATCH_ADD_RETAILER_SHOP_NUMBER, handleAddShopNumber)
}

export function* getRetailersCategories() {
  yield takeLatest(shopNumberActionTypes.WATCH_GET_RETAILER_SHOP_NUMBER, handleGetShopNumbers)
}

export function* WATCH_CLEAR_SHOP_NUMBERS() {
  yield takeLatest(shopNumberActionTypes.WATCH_CLEAR_SHOP_NUMBERS, handleClearShopNumbers)
}

export function* getRetailersCategoryById() {
  yield takeLatest(shopNumberActionTypes.WATCH_GET_RETAILER_SHOP_NUMBER_BY_ID, handleGetShopNumberById)
}

export function* updateRetailerCategory() {
  yield takeLatest(shopNumberActionTypes.WATCH_UPDATE_RETAILER_SHOP_NUMBER, handleUpdateShopNumber)
}

export function* deleteRetailerCategory() {
  yield takeLatest(shopNumberActionTypes.WATCH_DELETE_RETAILER_SHOP_NUMBER, handleDeleteShopNumber)
}

export function* retailerShopNumberSaga() {
  yield all([
    call(addRetailerShopNumber),
    call(getRetailersCategories),
    call(getRetailersCategoryById),
    call(updateRetailerCategory),
    call(deleteRetailerCategory)
  ]);
}