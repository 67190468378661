import {menuActionType} from './menu.actionType';

export const searchMenuItem = (search) => ({
    type: menuActionType.MENU_SEARCH,
    data: search
});

export const addMenuConfig = (menuConfig) => ({
    type: menuActionType.MENU_CONFIG,
    data: menuConfig
});