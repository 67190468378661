import { HTTPClient } from "./http.service";
import qs from "qs";
export const cohortService = {
  async getCohorts(params) {
    try {
      const { data } = await HTTPClient.get("/cohort/getCohort", params);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getCohort(id) {
    try {
      const { data } = await HTTPClient.get(`/cohort/getSingleCohort/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async addCohort(data) {
    try {
      const result = await HTTPClient.post(`/cohort`, data);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async editCohort(data, id) {
    try {
      const result = await HTTPClient.put(`/cohort/${id}`, data);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async getCohortUsers(id, params) {
    try {
      const { data } = await HTTPClient.get(`/cohort/users/${id}`, params);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async deleteCohortUser(params) {
    try {
      const { data } = await HTTPClient.delete(`/cohort/deleteuser`, {
        data: params,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async addCohortUsers(cohortId, users) {
    console.log(users, JSON.stringify(users));
    let data = { customers: users, cohortId: cohortId };
    try {
      let result = HTTPClient.customRequest({
        url: `/cohort/addUser`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
};
// tier changed
