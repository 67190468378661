
import {categoryActionTypes} from './category.actiontype';

const initalState = {
  loading: false,
  server_error: '',
  server_success: '',
  categories: [],
  categories_key_value: [],
  total_categories: null,
  category: {},
  showModal: false
}

export const RetailerCategory  = (state = initalState, action = {}) => {
  switch(action.type){

    case categoryActionTypes.SET_MODAL_SHOW:
      return {
        ...state,
        showModal: action.data
      };

    case categoryActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case categoryActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case categoryActionTypes.ADD_REQUEST:
      return {
        ...state,
        add_loading: true
      };

    case categoryActionTypes.ADD_COMPLETE:
      return {
        ...state,
        add_loading: false
      };

    case categoryActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add_loading: false,
        showModal: false,
        server_success: action.data
      }

    case categoryActionTypes.ADD_FAILED:
      return {
        ...state,
        server_error: action.data,
        server_success: ''
      }

    case categoryActionTypes.EDIT_SUCCESS:
      return {
        ...state,
        server_error: '',
        server_success: action.data
      }

    case categoryActionTypes.CLEAR_INITIAL:
      return {
        ...state,
        server_error: '',
        server_success: ''
      }

    //apis
    case categoryActionTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: action.data.docs,
        categories_key_value: action.data.docs.map((category) =>  {
          return {
            value: category.id,
            label: category.categoryName
          }
        }),
        total_categories: action.data.totalEntries
      }

    case categoryActionTypes.GET_CATEGORY_BY_ID:
      return {
        ...state,
        category: action.data,
        showModal: true
      }

    case categoryActionTypes.DELETE_CATEGORY_BY_ID:
      return {
        ...state,
        categories: state.categories.filter(category => category.id !== action.data)
      };

    case categoryActionTypes.UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map(category => {
          if (category.id === action.data.id) {
            category.categoryName = action.data.category_name
            category.categoryImage = action.data.category_image
            return category
          } else {
            return category
          }
        }),
        showModal: false,
        category: {}
      };

    case categoryActionTypes.SET_ERROR:
      return {
        ...state,
        server_error: action.data,
        server_success: ''
      } 

    default:
      return state;
  }
}