import { HTTPClient } from "./http.service";

export const voucherManagementService = {
  async getOutwardTypeList(params) {
    try {
      const {
        data: { data: usersDocs },
      } = await HTTPClient.get("/outwardInventory/category", params);
      return usersDocs;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getFrontendOutwardTypeList(params) {
    try {
      const {
        data: { data: usersDocs },
      } = await HTTPClient.get("/outwardFrontEndDesk/category/list", params);
      return usersDocs;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getInwardFrontEndDeskList(params) {
    try {
      const result = await HTTPClient.get("/inwardFrontEndDesk/", params);
      return result.data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async addOutwardType(data) {
    try {
      const {
        data: { data: rolesList },
      } = await HTTPClient.post("/outwardInventory/createCategory", data);
      return rolesList;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async addFrontEndOutwardType(data) {
    try {
      const {
        data: { data: rolesList },
      } = await HTTPClient.post("/outwardFrontEndDesk/createCategory", data);
      return rolesList;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async updateOutwardType(data, id) {
    try {
      const {
        data: { data: rolesList },
      } = await HTTPClient.put(`/outwardInventory/updateCategory/${id}`, data);
      return rolesList;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async updateFrontendOutwardType(data, id) {
    try {
      const {
        data: { data: rolesList },
      } = await HTTPClient.put(`/outwardFrontEndDesk/updateCategory/${id}`, data);
      return rolesList;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async debitCouponInventory(data, id) {
    // Create Outward Entry
    try {
      const result = await HTTPClient.post(`/outwardInventory/`, data);
      return result;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async debitFrontendDeskInventory(data, id) {
    // Create Frontend Desk Outward Entry
    try {
      const result = await HTTPClient.post(`/outwardFrontEndDesk/`, data);
      return result;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async addCorpusForRetailer(dataObj, id) {
    try {
      const {
        data: { data },
      } = await HTTPClient.post(
        `/retailerInventory/addCorpusDue/${id}`,
        dataObj
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getRetailersAssignedCorpus(id) {
    try {
      const {
        data: { data },
      } = await HTTPClient.get(`/retailerInventory/getCorpusDue/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async addInwardEntry(data) {
    try {
      const result = await HTTPClient.post("/inwardInventory", data);
      return result;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async getInwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClient.get("/inwardInventory/", params);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getOutwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClient.get("/outwardInventory/", params);
      return data;
    } catch (e) {
      console.log(e);
      return Promise.reject(e.response);
    }
  },
  async getFrontendOutwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClient.get("/outwardFrontEndDesk/", params);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getFrontendInwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClient.get("/inwardFrontEndDesk/", params);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getDefaultersList (params) {
    try {
      const {data:{data}} = await  HTTPClient.get('/inwardInventory/defaulters', params);
      return data;
    }catch(e){
      return Promise.reject(e.response);
    }
  },
  async getRetailerDataById (id) {
    try {
      const {data:{data}} = await  HTTPClient.get(`/retailerInventory/communication/${id}`);
      return data;
    }catch(e){
      return Promise.reject(e.response);
    }
  },
  async addRetailerPOC (data, id) {
    try{
      const result = await HTTPClient.put(`/retailerInventory/communication/${id}`, data);
      return result
    }catch(e) {
      return Promise.reject(e.data.status);
    }
  },
  async backendOutwardEntry (dataObj) {
    try {
      const {
        data: { data },
      } = await HTTPClient.post("/outwardInventory", dataObj);
      return data;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async frontEndOutwardEntry (dataObj) {
    try {
      const {
        data: { data },
      } = await HTTPClient.post("/outwardFrontEndDesk", dataObj);
      return data;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async getRetailersCorpusList (params) {
    try{
      const {data: {data}} = await HTTPClient.get(`/retailerInventory/`, params);
      return data
    }catch(e) {
      return Promise.reject(e.data.status);
    }
  }
}
