import {HTTPClient} from './http.service';

export const overviewService = {
  async getData(){
    try {
      const result = await HTTPClient.get('/mallDashboardNew');
      return result;
    } catch(error) {
      return error;
    }
  },
  async getParkingData() {
    try {
      const result = await HTTPClient.get('/parking/getBookings/graph');
      return result;
    } catch(error) {
      return error;
    }
  }
}
