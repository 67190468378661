import axios from "axios";
import store from "../../store/store";
import { logoutStart } from "../../store/auth/auth.action";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../style/sweetAlert.scss";

// const BAGGAGE_BASE_URL =
//   "https://stage-asgard-baggage.futureretail.tech/v1/company/";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const axiosInstanceV2 = axios.create({
  baseUrl: process.env.REACT_APP_BASE_URL_V2
})

const axiosBaggageInstance = axios.create({
  baseURL: process.env.REACT_APP_BAGGAGE_CONST_URL,
});

const instanceCreator = ($axios) => ({
  saveHeader({ key, value }) {
    $axios.defaults.headers.common[key] = value;
  },
  deleteHeader(key) {
    delete $axios.defaults.headers.common[key];
  },
  get(url, params) {
    return $axios.get(url, { params });
  },
  post(resource, data) {
    return $axios.post(resource, data);
  },
  put(resource, data) {
    return $axios.put(resource, data);
  },
  delete(resource, data={}) {
    return $axios.delete(resource, data);
  },
  deleteWithBody(resource, data) {
    return $axios.delete(resource, { data: data });
  },
  customRequest(config) {
    return $axios(config);
  },
  successHandler(response) {
    return response;
  },
  errorHandler(error) {
    const { response } = error;
    const {
      data: { status },
    } = response;
    if (status.code === 401 && status.message === "Token Expired") {
      //window.location = '/auth/logout';
      store.dispatch(logoutStart());
      // setTimeout(() => {
      //   sagaMiddleware.run(RootSaga);
      // },100);
    }
    withReactContent(Swal).fire(status.message, "", "info");
    // store.dispatch(appConstOnError({type: 'error', message: status.message}));
    return Promise.reject(response);

    // if (status.code >= 400 && status.code < 500) {
    //   store.dispatch(appConstOnError({type: 'error', message: status.message}));
    // } else {
    //   store.dispatch(appConstOnError({type: 'error', message: 'Something went wrong'}));
    // }
  },
  interceptorRef: null,
  mountResponseInterceptor() {
    this.interceptorRef = $axios.interceptors.response.use(
      this.successHandler,
      this.errorHandler
    );
  },
  ejectResponseInterceptor() {
    $axios.interceptors.response.eject(this.interceptorRef);
  },
});

export const HTTPClient = instanceCreator(axiosInstance);
export const BaggageHTTPClient = instanceCreator(axiosBaggageInstance);
HTTPClient.mountResponseInterceptor();

export const HTTPClientV2 = instanceCreator(axiosInstanceV2)
HTTPClientV2.mountResponseInterceptor();

