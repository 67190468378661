export default {
  self: {},
  items: [
    {
      title: "Communication",
      key: "communication",
      root: true,
      icon: "flaticon2-bell-5",
      bullet: "dot",
      page: "communication",
      submenu: [
        {
          title: "Deep Links Management",
          key: "deepLink",
          page: "communication/deep-link-management",
          bullet: "dot",
        },
        {
          title: "Notification Management",
          key: "notificationCenter",
          bullet: "dot",
          page: "communication/notifications",
        },
        {
          title: "Referral Code Management",
          key: "referralCampaign",
          bullet: "dot",
          page: "communication/referral",
        },
        {
          title: "Survey Management",
          key: "survey",
          page: "communication/survey",
          bullet: "referral1",
        },
        {
          title: "Cohort Management",
          key: "targeting-cohort",
          page: "communication/cohorts",
          bullet: "dot",
        },
      ],
    },
    {
      title: "Content",
      key: "content",
      root: true,
      icon: "flaticon2-sheet",
      bullet: "dot",
      page: 'content',
      submenu: [
        {
          title: "Banner Management",
          key: "banner",
          bullet: "dot",
          page: "content/banner",
        },
        {
          title: "Onboarding Screens",
          key: "onboardingScreen",
          bullet: "dot",
          page: "content/onboarding-screens",
        },
        {
          title: "Mall Feed Management",
          key: "mallFeed",
          bullet: "dot",
          page: "content/mall-feed-managers",
        },
      ],
    },
    {
      title: "Customer Management",
      key: "customers",
      root: true,
      icon: "flaticon2-group",
      bullet: "dot",
      page: "customer-management",
      submenu: [],
    },
    {
      title: "Loyalty",
      key: "loyalty",
      root: true,
      icon: "flaticon2-layers-2",
      bullet: "dot",
      page: "loyalty",
      submenu: [
        {
          title: "Coupon Management",
          key: "couponManagement",
          icon: "flaticon-price-tag",
          page: "loyalty/coupon-management",
          bullet: "dot",
        },
        {
          title: "Coupon Management",
          key: "couponManagementPro",
          icon: "flaticon-price-tag",
          page: "loyalty/coupon-management-full",
          bullet: "dot",
        },
        {
          title: "Reward Management",
          key: "rewardManagement",
          root: true,
          icon: "flaticon-price-tag",
          page: "loyalty/reward-management",
          bullet: "dot",
        },
        {
          title: "Refute Management",
          key: "refuteManagement",
          icon: "flaticon-price-tag",
          page: "loyalty/refute-management",
          bullet: "dot",
        },
        {
          title: "Scan Reconcilation",
          key: "scanReconcile",
          icon: "flaticon-price-tag",
          page: "loyalty/scan-reconcile",
          bullet: "dot",
        },
        {
          title: "Rules Engine",
          key: "rule",
          root: true,
          icon: "flaticon-interface-1",
          page: "loyalty/rule-engine",
          bullet: "dot",
        },
        {
          title: "Delight Transfer",
          key: "customerDelight",
          root: true,
          icon: "flaticon-price-tag",
          page: "loyalty/customer-delight",
          bullet: "dot",
        },
        {
          title: "Shop and Win",
          key: "scanCampaign",
          root: true,
          icon: "flaticon-price-tag",
          page: "loyalty/shop-and-win",
          bullet: "dot",
        },
        {
          title: "Spin the Wheel",
          key: "scanCampaign",
          root: true,
          icon: "flaticon-price-tag",
          page: "loyalty/spin-the-wheel",
          bullet: "dot",
        },
        {
          title: "Scratch Card",
          key: "scratchCard",
          icon: "flaticon-price-tag",
          page: "loyalty/scratch-card-management",
          bullet: "dot",
        },
      ],
    },
    {
      title: "Retailers",
      key: "retailers",
      root: true,
      icon: "flaticon2-tag",
      bullet: "dot",
      page: "retailers",
      submenu: [],
    },
    // {
    //   title: "Planned Visits",
    //   key: "bookingManagement",
    //   root: true,
    //   icon: "flaticon2-dashboard",
    //   page: "personalised-plan-visit",
    //   bullet: "dot",
    //   submenu: [],
    // },
    {
      title: "Plan Visit Booking",
      key: "bookingManagement",
      root: true,
      icon: "flaticon2-dashboard",
      page: "booking-management",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Parking Management",
      key: "parking",
      root: true,
      icon: "fas fa-parking",
      page: "parking",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Baggage Services",
      key: "baggage",
      root: true,
      icon: "fas fa-shopping-bag",
      page: "baggage-services",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Dineout Orders",
      key: "dineout",
      root: true,
      icon: "fas fa-utensils",
      page: "dineout",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Team Management",
      key: "userManagement",
      root: true,
      icon: "flaticon2-user-1",
      page: "user-management",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Reports",
      key: "reports",
      root: true,
      icon: "fas fa-newspaper",
      bullet: "dot",
      page: "reports",
      submenu: [
        {
          title: "Coupon Redemption Report",
          key: "couponRedemptionReport",
          bullet: "dot",
          page: "reports/coupon-redemption",
        },
        {
          title: "Reward Redemption Report",
          key: "rewardRedemptionReport",
          bullet: "dot",
          page: "reports/reward-redemption",
        },
        {
          title: "Scan Report",
          key: "customerScansReport",
          bullet: "dot",
          page: "reports/scan-reports",
        },
        {
          title: "Contactless Payment Report",
          key: "paymentTransaction",
          bullet: "dot",
          page: "reports/contactless-payment-log-report",
        },
        {
          title: "Favourite Stores Report List",
          key: "favouriteStoreReport",
          bullet: "dot",
          page: "fav-store-report-list",
        },
        {
          title: "Report Management",
          key: "reportsManagement",
          bullet: "dot",
          page: "reports/report-list",
        },
        {
          title: "Customers by DOB",
          key: "customerByDob",
          bullet: "dot",
          page: "customers-by-dob-list",
        },
        {
          title: "Customers by Anniversary",
          key: "customerByAnniversary",
          bullet: "dot",
          page: "customers-by-anniversary-list",
        },
      ],
    },
    {
      title: "Issue Management",
      key: "issueManagement",
      root: true,
      icon: "fas fa-tasks",
      page: "issue-management",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Handler Apps",
      key: "handlerApp",
      root: true,
      icon: "fas fa-tablet",
      page: "handler",
      bullet: "dot",
      submenu: [],
    },
    {
      title: "Concierge Services",
      key: "concierge",
      root: true,
      icon: "flaticon-notepad",
      bullet: "dot",
      page: "concierge",
      submenu: [
        {
          title: "Scan Service",
          key: "conciergeScan",
          bullet: "dot",
          page: "concierge/scan-service",
        },
        {
          title: "Physical Coupon Distribution",
          key: "conciergePhysicalVoucher",
          bullet: "dot",
          page: "concierge/concierge-physical-distribution-coupon-vouchers",
        },
        {
          title: "Physical Reward Distribution",
          key: "conciergePhysicalVoucher",
          bullet: "dot",
          page: "concierge/concierge-physical-distribution-reward-vouchers",
        },
        {
          title: "Bulk Coupon/Reward Transfer",
          key: "bulkVoucherTransfer",
          bullet: "dot",
          page: "concierge/bulk_reward_transfer",
        },
        {
          title: "Other Issues",
          key: "conciergeIssue",
          bullet: "dot",
          page: "concierge/other-issues",
        },
      ],
    },
    {
      title: "Voucher Management",
      key: "voucherManagement",
      root: true,
      icon: "flaticon2-open-box",
      page: "voucher-management/all",
      bullet: "dot",
      submenu: [
        {
          title: "Retailers",
          key: "retailerInventory",
          bullet: "dot",
          page: "voucher-management/retailer-inventory",
        },
        {
          title: "Voucher Inward",
          key: "inwardInventory",
          page: "voucher-management/inwards",
          bullet: "dot",
        },
        {
          title: "Voucher Outward",
          key: "outwardInventory",
          bullet: "dot",
          page: "voucher-management/outwards",
        },
      ],
    },
    {
      title: "Frontdesk Management",
      key: "frontEndDeskVoucherManagement",
      root: true,
      icon: "flaticon2-open-box",
      bullet: "dot",
      page: "frontdesk-voucher-management/inwards'",
      submenu: [
        {
          title: "Retailers",
          key: "retailerFrontdesk",
          bullet: "dot",
          page: "frontdesk-voucher-management/retailer-inventory",
        },
        {
          title: "Voucher Inward",
          key: "inwardFrontEndDesk",
          page: "frontdesk-voucher-management/inwards",
          bullet: "dot",
        },
        {
          title: "Voucher Outward",
          key: "outwardFrontEndDesk",
          bullet: "dot",
          page: "frontdesk-voucher-management/outwards",
        },
      ],
    },
    {
      title: "Static Pages",
      key: "staticPage",
      root: true,
      icon: "flaticon-file-1",
      bullet: "dot",
      page: "static-pages",
      submenu: [],
    },
  ],
};
