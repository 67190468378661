import { HTTPClientV2, HTTPClient } from "./http.service";
import qs from "qs";

export const voucherManagementServiceV2 = {
  async getFrontendOutwardTypeList(params) {
    try {
      const {
        data: { data: usersDocs },
      } = await HTTPClientV2.get("/outwardFrontEndDesk/category/list", params);
      return usersDocs;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async debitCouponInventory(data, id) {
    // Create Outward Entry
    try {
      const result = await HTTPClientV2.post(`/outwardInventory/`, data);
      return result;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async debitFrontendDeskInventory(data, id) {
    // Create Frontend Desk Outward Entry
    try {
      const result = await HTTPClientV2.post(`/outwardFrontEndDesk/`, data);
      return result;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async addCorpusForRetailer(dataObj, id) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.post(
        `/retailerInventory/addCorpusDue/${id}`,
        dataObj
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getRetailersAssignedCorpus(id) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.get(`/retailerInventory/getCorpusDue/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async addInwardEntry(data) {
    try {
      const result = await HTTPClientV2.post("/inwardInventory", data);
      return result;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async getInwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.get("/inwardInventory/", params);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getOutwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.get("/outwardInventory/", params);
      return data;
    } catch (e) {
      console.log(e);
      return Promise.reject(e.response);
    }
  },
  async getFrontendOutwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.get(`${process.env.REACT_APP_BASE_URL_V2}/outwardFrontEndDesk/`, params);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getFrontendInwardEntryList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.get("/inwardFrontEndDesk/", params);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getDefaultersList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.get("/inwardInventory/defaulters", params);
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async addRetailerPOC(data, id) {
    try {
      const result = await HTTPClientV2.put(
        `/retailerInventory/communication/${id}`,
        data
      );
      return result;
    } catch (e) {
      return Promise.reject(e.data.status);
    }
  },
  async backendOutwardEntry(dataObj) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.post("/outwardInventory", dataObj);
      return data;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async frontEndOutwardEntry(dataObj) {
    try {
      const {
        data: { data },
      } = await HTTPClientV2.post("/outwardFrontEndDesk", dataObj);
      return data;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async getRetailersCorpusList(params) {
    try {
      const {
        data: { data },
      } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/retailerInventory/getCorpusDue/?yearStart=2021-04-01&yearEnd=2022-03-31&pageNo=1&pageSize=10&sort=asc`
      );

      return data;
    } catch (e) {
      return Promise.reject(e.data.status);
    }
  },
  async addPocDetails(id, data) {
    try {
      await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/retailerInventory/addRetailerPoc/${id}`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      // return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getRetailerCorpusSummaryById(id, params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/retailerInventory/getCorpusSummary/${id}`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getRetailerFrontdeskCorpusSummaryById(id, params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/retailerFrontdesk/summary/${id}`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getRetailerCorpus(id, params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/retailerInventory/getCorpusDue/${id}`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async addRetailerCorpus(id, data) {
    try {
      await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/retailerInventory/addCorpusDue/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
      return;
    } catch (e) {
      console.log(e);
      return Promise.reject(e.response);
    }
  },

  async getAllInwardList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/inwardInventory/`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getAllOutwardList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/outwardInventory/`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getRetailerCorpusList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/retailerInventory/getCorpusDue/`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getFrontdeskRetailerCorpusList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/retailerFrontdesk/`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getInwardFrontendDeskList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/inwardFrontEndDesk/`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getOutwardFrontendDeskList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/outwardFrontEndDesk/`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async addBackendInwardEntry(params) {
    try {
      const { data } = await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/inwardInventory/`,
        method: "post",
        data: qs.stringify(params),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getRetailerInwards(id, params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/inwardInventory/${id}`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getRetailerOutwards(id, params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/outwardInventory/${id}`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async getBackendOutwardList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/outwardInventory/category`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getFrontdeskOutwardCategoryList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/inwardFrontEndDesk/category`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async getOutwardCategoryList(params) {
    try {
      const { data } = await HTTPClient.get(
        `${process.env.REACT_APP_BASE_URL_V2}/outwardInventory/category?`,
        params
      );
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async addBackendOutwardEntry(params) {
    try {
      const { data } = await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/outwardInventory/`,
        method: "post",
        data: qs.stringify(params),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async addFrontdeskOutwardEntry(params) {
    try {
      const { data } = await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/outwardFrontEndDesk/`,
        method: "post",
        data: qs.stringify(params),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },

  async addOutwardType(params) {
    try {
      const { data } = await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/outwardInventory/createCategory`,
        method: "post",
        data: qs.stringify(params),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async addFrontdeskOutwardType(params) {
    try {
      const { data } = await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/inwardFrontEndDesk/createCategory`,
        method: "post",
        data: qs.stringify(params),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      return data;
    } catch (e) {
      return Promise.reject(e.response);
    }
  },
  async updateOutwardType(params, id) {
    try {
      const { data } = await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/outwardInventory/updateCategory/${id}`,
        method: "put",
        data: qs.stringify(params),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      return;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async updateFrontendOutwardType(params, id) {
    try {
      const { data } = await HTTPClient.customRequest({
        url: `${process.env.REACT_APP_BASE_URL_V2}/inwardFrontEndDesk/updateCategory/${id}`,
        method: "put",
        data: qs.stringify(params),
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      return;
    } catch (e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
};
