export const urlsMappings = {
  notifications: "/communication/notifications",
  deepLink : "/communication/deep-link-management",
  referral: "/communication/referral",
  survey: "/communication/survey",
  cohort: "/communication/cohort",
  banner: "/content/banner",
  onboardingScreens: "/content/onboarding-screens",
  mallFeedManagers: "/content/mall-feed-managers",
  customerManagement: "/customer-management",
  refuteManagement: "/loyalty/refute-management",
  couponManagement: "/loyalty/coupon-management",
  couponManagementFull: "/loyalty/coupon-management-full",
  scratchCardManagement: "/loyalty/scratch-card-management",
  ruleEngine: "/loyalty/rule-engine",
  rewardManagement: "/loyalty/reward-management",
  shopAndWin: "/loyalty/shop-and-win",
  spinTheWheel: "/loyalty/spin-the-wheel",
  customerDelight: "/loyalty/customer-delight",
  retailers: "/retailers",
  userManagement: "/user-management",
  bookingManagement: "/booking-management",
  parking: "/parking",
  baggageServices: "/baggage-services",
  dineout: "/dineout",
  scanReports: "/scan-reports",
  couponRedemption: "/reports/coupon-redemption",
  rewardRedemption: "/reports/reward-redemption",
  contactlessPaymentLogReport: "/reports/contactless-payment-log-report",
  reportList: "/reports/report-list",
  favStoreReportList: "/fav-store-report-list",
  customersByDobList: "/customers-by-dob-list",
  customersByAnniversaryList: "/customers-by-anniversary-list",
  otherIssues: "/concierge/other-issues",
  issueManagement: "/issue-management",
  conciergePhysicalDistributionCouponVouchers: "/concierge/concierge-physical-distribution-coupon-vouchers",
  conciergePhysicalDistributionRewardVouchers: "/concierge/concierge-physical-distribution-reward-vouchers",
  personalisedplanVisit: "/personalised-plan-visit",
  handler: "/handler",
  scanService: "/concierge/scan-service",
  scanReconcile: "/loyalty/scan-reconcile",
  voucherMgmt: {
    inward: '/voucher/backend/inwards',
    outward: '/voucher/backend/outwards',
    retailers: '/voucher/backend/voucher-retailer'
  },
  frontdeskMgmt: {
    inward: '/voucher/frontdesk/inwards',
    outward: '/voucher/frontdesk/outwards'
  },
  voucherManagement : {
    retailerInventory: '/voucher-management/retailer-inventory',
    inwardInventory:'/voucher-management/inwards',
    outwardInventory:'/voucher-management/outwards'
  },
  frontEndDeskVoucherManagement : {
    retailerFrontdesk:'/frontdesk-voucher-management/outwards',
    inwardFrontEndDesk: '/frontdesk-voucher-management/retailer-inventory',
    outwardFrontEndDesk:'frontdesk-voucher-management/inwards',
  },
  staticPage: "/static-pages",

};

export const menuUrlMapping = {
  "/communication/notifications": {
    menu: 'communication',
    subMenu: 'notificationCenter'
  },
  "/communication/deep-link-management": {
    menu: 'communication',
    subMenu: 'deepLink'
  },
  "/communication/referral": {
    menu: 'communication',
    subMenu: 'referralCampaign'
  },
  "/communication/survey": {
    menu: 'communication',
    subMenu: 'survey'
  },
  "/communication/cohort": {
    menu: 'communication',
    subMenu: 'targeting-cohort'
  },
  "/content/banner": {
    menu: 'content',
    subMenu: 'banner'
  },
  "/content/onboarding-screens": {
    menu: 'content',
    subMenu: 'onboardingScreen'
  },
  "/content/mall-feed-managers": {
    menu: 'content',
    subMenu: 'mallFeed'
  },
  "/customer-management": {
    menu: 'customers',
  },
  "/loyalty/refute-management": {
    menu: 'loyalty',
    subMenu: 'refuteManagement'
  },
  "/loyalty/coupon-management": {
    menu: 'loyalty',
    subMenu: 'couponManagement'
  },
  "/loyalty/coupon-management-full": {
    menu: 'loyalty',
    subMenu: 'couponManagementPro'
  },
  "/loyalty/scratch-card-management": {
    menu: 'loyalty',
    subMenu: 'scratchCard'
  },
  "/loyalty/rule-engine": {
    menu: 'loyalty',
    subMenu: 'rule'
  },
  "/loyalty/reward-management": {
    menu: 'loyalty',
    subMenu: 'rewardManagement'
  },
  "/loyalty/shop-and-win": {
    menu: 'loyalty',
    subMenu: 'scanCampaign'
  },
  "/loyalty/spin-the-wheel": {
    menu: 'loyalty',
    subMenu: 'scanCampaign'
  },
  "/loyalty/customer-delight": {
    menu: 'loyalty',
    subMenu: 'customerDelight'
  },

  "/retailers": {
    menu: 'retailers',
  },

  "/user-management": {
    menu: 'userManagement',
  },
  "/booking-management": {
    menu: 'bookingManagement',
  },
  "/parking": {
    menu: 'parking',
  },
  "/baggage-services": {
    menu: 'baggage',
  },
  "/dineout": {
    menu: 'dineout',
  },
  "/reports/scan-reports": {
    menu: 'reports',
    subMenu: 'rewardRedemptionReport'
  },
  "/reports/coupon-redemption": {
    menu: 'reports',
    subMenu: 'couponRedemptionReport'
  },
  "/reports/reward-redemption": {
    menu: 'reports',
    subMenu: 'rewardRedemptionReport'
  },
  "/reports/contactless-payment-log-report": {
    menu: 'reports',
    subMenu: 'paymentTransaction'
  },
  "/reports/report-list": {
    menu: 'reports',
    subMenu: 'reportsManagement'
  },
  "/fav-store-report-list": {
    menu: 'reports',
    subMenu: 'favouriteStoreReport'
  },
  "/customers-by-dob-list": {
    menu: 'reports',
    subMenu: 'customerByDob'
  },
  "/customers-by-anniversary-list": {
    menu: 'reports',
    subMenu: 'customerByAnniversary'
  },
  "/voucher": {
    menu: 'content',
    subMenu: 'banner'
  },
  "/concierge/other-issues": {
    menu: 'concierge',
    subMenu: 'conciergeIssue'
  },
  "/issue-management": {
    menu: 'issueManagement',
    // subMenu: 'issueManagement'
  },
  "/concierge/concierge-physical-distribution-coupon-vouchers": {
    menu: 'concierge',
    subMenu: 'conciergePhysicalVoucher'
  },
  "/concierge/concierge-physical-distribution-reward-vouchers": {
    menu: 'concierge',
    subMenu: 'conciergePhysicalVoucher'
  },
  "/personalised-plan-visit": {
    menu: 'bookingManagement',
  },
  "/handler": {
    menu: 'handlerApp',
  },
  "/concierge/scan-service": {
    menu: 'concierge',
    subMenu: 'conciergeScan'
  },
  "/loyalty/scan-reconcile": {
    menu: 'loyalty',
    subMenu: 'scanReconcile'
  },
  "/voucher-management" : {
    menu:'voucherManagement',
    subMenu:'retailerInventory'
  },
  "/frontdesk-voucher-management" : {
    menu:'frontEndDeskVoucherManagement',
    subMenu:'retailerFrontdesk'
  },
  "/static-pages" : {
    menu:'staticPage',
    // subMenu:'retailerFrontdesk'
  }
}
