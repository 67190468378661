import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { change_pageHeading } from "../store/appStore/appStore.action";
import { checkModuleCRUDstatus } from "../store/modules_mallsStore/mm.actions";
import { PageName } from "../utils/pageTitles";
import withAllowed from "../utils/withAllowed";
const Banners = lazy(() => import("./ContentManagement/Banners/index"));
const OnboardingScreens = lazy(() =>
  import("./ContentManagement/OnboardingScreens/index")
);
const MallFeedManager = lazy(() =>
  import("./ContentManagement/MallFeedManager/index")
);



const ContentRoutes = ({ changePageHeading, match, ...props }) => {
  const {path, url} = match;
  const renderComponentFP = (
    Component,
    pageTitle = { title: "", moduleKey: "" }
  ) => {
    return (props) => {
      // changePageHeading(pageTitle.title);
      // checkModuleCRUDstatus(pageTitle.moduleKey);
      return <Component {...props} />;
    };
  };
  return <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from={url} to={`${url}/deep-link-management`} />
        }
        <Route
          path="/content/banner"
          render={(props) =>
            renderComponentFP(withAllowed(Banners), PageName.BANNERS)(props)
          }
        />
        <Route
          path="/content/onboarding-screens"
          render={(props) =>
            renderComponentFP(
              withAllowed(OnboardingScreens),
              PageName.ONBORADINGSCREEN
            )(props)
          }
        />
        <Route
          path="/content/mall-feed-managers"
          render={(props) =>
            renderComponentFP(
              withAllowed(MallFeedManager),
              PageName.MALLFEEDMANAGER
            )(props)
          }
        />
    </Switch>

};

const mapStateToProps = ({ appData }) => ({
  mallModalOpen: appData.mallSwitchOpen,
});
const mapDisptachToProps = (dispatch) => ({
  changePageHeading: (pageHeading) => dispatch(change_pageHeading(pageHeading)),
  checkModuleCRUDstatus: (moduleName) =>
    dispatch(checkModuleCRUDstatus(moduleName)),
});
export default connect(mapStateToProps, mapDisptachToProps)(ContentRoutes);
