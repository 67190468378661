import {HTTPClient} from './http.service';

export const retailerShopNumberService = {
  async addShopNumber(data) {
    try {
      const result = await HTTPClient.post('/retailers/shop', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getShopNumbers(data) {
    try {
      const result = await HTTPClient.get('/retailers/shop', data);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getShopNumberById(id) {
    try {
      const result = await HTTPClient.get(`/retailers/shop/${id}`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async updateShopNumber(data, id) {
    try {
      const result = await HTTPClient.put(`/retailers/shop/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async deleteShopNumber(id) {
    try {
      const result = await HTTPClient.delete(`/retailers/shop/${id}`);
      return result;
    } catch(error) {
      return error;
    }
  },
}