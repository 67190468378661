export const authActionTypes = {
  LOGIN_INIT: 'LOGIN_INIT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT_INIT: 'LOGOUT_INIT',
  FRGTPASS_EMAILINIT: 'FRGTPASS_EMAILINIT',
  FRGTPASS_EMAILSUCCESS: 'FRGTPASS_EMAILSUCCESS',
  FRGTPASS_EMAILFAIL: 'FRGTPASS_EMAILFAIL' ,
  REGISTER_INIT: 'REGISTER_INIT',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
};
// export const authActionTypes = {
//   Login: "[Login] Action",
//   Logout: "[Logout] Action",
//   Register: "[Register] Action",
//   UserRequested: "[Request User] Action",
//   UserLoaded: "[Load User] Auth API"
// };