
import {retailerActionTypes} from './retailer.actiontype';

const initalState = {
  loading: false,
  retailers: [],
  total_retailers: null,
  retail_page:{
    pageNo: 1, 
    pageSize: 10
  },
  shop_page:{
    pageNo: 1, 
    pageSize: 10
  },
  floor_page: {
    pageNo: 1, 
    pageSize: 10
  },
  category_page:{
    pageNo: 1, 
    pageSize: 10
  }
}

export const Retailer  = (state = initalState, action = {}) => {
  
  switch(action.type){

    case retailerActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case retailerActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case retailerActionTypes.GET_RETAILERS:
      return {
        ...state,
        retailers: action.data.docs,
        total_retailers: action.data.totalEntries
      }

    case retailerActionTypes.RETAILER_SET_PAGE:
      return Object.assign({}, state, {
        ...state,
        [action.data.name]: {
          ...state[action.data.name],
          pageSize: action.data.value.pageSize,
          pageNo: action.data.value.pageNo
        }
      });

    case retailerActionTypes.RETAILER_SET_INIT:
      return Object.assign({}, state, {
        ...state,
        retail_page:{
          pageNo: 1, 
          pageSize: 10
        },
        shop_page:{
          pageNo: 1, 
          pageSize: 10
        },
        floor_page:{
          pageNo: 1, 
          pageSize: 10
        },
        category_page:{
          pageNo: 1, 
          pageSize: 10
        }
      });

    default:
      return state;
  }
}