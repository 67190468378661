import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
// import * as auth from "../../store/ducks/auth.duck";
// import { login } from "../../crud/auth.crud";

import {loginStart} from '../../store/auth/auth.action';

function Login(props) {
  const { login_init, errorObj, loginProcess } = props;
  const setLoading = useState(false)[1];
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  return (
    <>
      <div className="kt-login__body loginForm">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3 className="font-size-h1 title">
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
            {errorObj &&
            (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{errorObj.message || "Something went wrong, please try again after sometimes"}</div>
              </div>
            )}
          </div>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = "Email id is required for signing in."
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email =  "Email id is not valid."
              }

              if (!values.password) {
                errors.password = "Password is required for signing in."
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              login_init({email: values.email, password: values.password})
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >

                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email *"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.email})}`}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.email}</div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    placeholder="Password *"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${clsx({'is-invalid':errors.password})}`}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{errors.password}</div>
                  </div>
                </div>

                <div className="kt-login__actions">
                  <Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    Forgot Password?
                  </Link>

                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={loginProcess}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loginProcess
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}


const mapDispatchToProps = dispatch => ({
  login_init: emailAndPassword => dispatch(loginStart(emailAndPassword))
});
const mapStateToProps = ({auth}) => ({
  errorObj: auth.loginError,
  loginProcess: auth.loginProcess
})
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
