
import {couponActionTypes} from './coupon.actiontype';

const initalState = {
  active:{
    pageSize: 10,
    pageNo: 1
  },
  expired:{
    pageSize: 10,
    pageNo: 1
  },
}

export const Coupon  = (state = initalState, action = {}) => {
  switch(action.type){
    case couponActionTypes.COUPON_SET_PAGE:
      return Object.assign({}, state, {
        ...state,
        [action.data.name]: {
          ...state[action.data.name],
          pageSize: action.data.value.pageSize,
          pageNo: action.data.value.pageNo
        }
      });

    case couponActionTypes.COUPON_SET_INIT:
      return initalState;
    default:
      return state;
  }
}