import {takeLatest, call, all, put} from 'redux-saga/effects';
import {modules_success, malls_switch_success} from './mm.actions';
import {mmActiontypes} from './mm.actionTypes';
import {appService, HTTPClient, BaggageHTTPClient} from '../../service';


export function* getInitModules() {
  try {
    const modulesList =  yield appService.getModulesList();
    const menuObj = yield appService.getMenuObj();
    yield put(modules_success({modulesList, menuObj}));
    return modulesList;
  }catch(e){
    console.log(e);
  }
}

export function* modulesInit() {
  yield takeLatest(mmActiontypes.MODULES_INIT, getInitModules);
}

export function* mallSwitchEvent({payload: mallId}) {
  try {
    yield HTTPClient.saveHeader({key: 'mallId', value: `mall_${mallId}`});

    yield BaggageHTTPClient.saveHeader({key: 'mallId', value: `mall_${mallId}`});

    yield* getInitModules();
    yield put(malls_switch_success());
  }catch(e){
    console.log(e);
  }
  
}

export function* mallSwithInit() {
  yield takeLatest(mmActiontypes.MALL_SWITCH_INIT, mallSwitchEvent)
}

export function* mmSaga() {
  yield all([
    call(modulesInit),
    call(mallSwithInit)
  ]);
}

