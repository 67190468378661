import { HTTPClient } from "./http.service";

export const IssueManagement = {
  async getPendingIssues(param) {
    try {
      const data = await HTTPClient.get("/issueManagement/", param);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getIssueDetail (id) {
    try {
      const data = await HTTPClient.get(`/issueManagement/${id}`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateIssue (data, id) {
    try {
      const result = await HTTPClient.put(`/issueManagement/${id}`, data);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  }
};