import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { change_pageHeading } from "../store/appStore/appStore.action";
import { checkModuleCRUDstatus } from "../store/modules_mallsStore/mm.actions";
import { PageName } from "../utils/pageTitles";
import withAllowed from "../utils/withAllowed";
const ScanService = lazy(() => import("./ScanService/index"));
const OtherIssues = lazy(() => import("./OtherIssues"));
const ConciergePhysicalDistVoucher = lazy(() =>
  import("./Concierge/PhysicalDistVoucher/index")
);
const BulkRewardTransfer = lazy(() => import("./BulkVoucherTransfer"));

const ConciergePhysicalRewardDistVoucher = lazy(() =>
  import("./Concierge/PhysicalDistVoucher/index1")
);


const ContentRoutes = ({ changePageHeading, match, ...props }) => {
  const {path, url} = match;
  const renderComponentFP = (
    Component,
    pageTitle = { title: "", moduleKey: "" }
  ) => {
    return (props) => {
      // changePageHeading(pageTitle.title);
      // checkModuleCRUDstatus(pageTitle.moduleKey);
      return <Component {...props} />;
    };
  };
  return <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from={url} to={`${url}/scan-service`} />
        }
        <Route
          path="/concierge/scan-service"
          render={(props) =>
            renderComponentFP(
              withAllowed(ScanService),
              PageName.SCANSERVICE
            )(props)
          }
        />

        <Route
          path="/concierge/concierge-physical-distribution-coupon-vouchers"
          render={(props) =>
            renderComponentFP(
              withAllowed(ConciergePhysicalDistVoucher),
              PageName.PHYSICALVOUCHERDISTRIBUTION
            )(props)
          }
        />

        <Route
          path="/concierge/concierge-physical-distribution-reward-vouchers"
          render={(props) =>
            renderComponentFP(
              withAllowed(ConciergePhysicalRewardDistVoucher),
              PageName.PHYSICALREWARDISTRIBUTION
            )(props)
          }
        />

        <Route
          path="/concierge/bulk_reward_transfer"
          render={(props) =>
            renderComponentFP(
              BulkRewardTransfer,
              PageName.BULK_REWARD_TRANSFER
            )(props)
          }
        />

        <Route
          path="/concierge/other-issues"
          render={(props) =>
            renderComponentFP(
              withAllowed(OtherIssues),
              PageName.OTHERISSUES
            )(props)
          }
        />
    </Switch>

};

const mapStateToProps = ({ appData }) => ({
  mallModalOpen: appData.mallSwitchOpen,
});
const mapDisptachToProps = (dispatch) => ({
  changePageHeading: (pageHeading) => dispatch(change_pageHeading(pageHeading)),
  checkModuleCRUDstatus: (moduleName) =>
    dispatch(checkModuleCRUDstatus(moduleName)),
});
export default connect(mapStateToProps, mapDisptachToProps)(ContentRoutes);
