import {appStoreActiontype} from './appStore.actiontype';

export const appConst_init = () => ({
  type: appStoreActiontype.APPCONSTANT_INIT
});

export const appConstSuccess = (appConst) => ({
  type: appStoreActiontype.APPCONSTANT_SUCCESS,
  payload: appConst
});

export const appConstOnLogout = () => ({
  type: appStoreActiontype.APPCONSTANT_ONLOGOUT
});

export const appConstOnError = (errorObj) => ({
  type: appStoreActiontype.APPSTORE_ERROR,
  payload: errorObj
});

export const appConstClearError = () => ({
  type: appStoreActiontype.APPSTORE_CLEAR_ERROR
})
export const showToastMessage = (toastObj) => {
  return {
    type: appStoreActiontype.SHOW_TOAST,
    payload: toastObj
  }
}

export const clearToastMessage = () => ({
  type: appStoreActiontype.CLEAR_TOAST
});

export const change_pageHeading = pageHeading => ({
  type: appStoreActiontype.PAGEHEADING_CHANGE,
  payload: pageHeading
});

export const onTierData = tireData => ({
  type: appStoreActiontype.TIER_DATA,
  payload: tireData
});


