import { HTTPClient } from "./http.service";
export const authService = {
  async login(data) {
    const constVariable = {
      device: "controllerApp",
      deviceId: new Date().toString(),
      build: "1.0.0",
    };
    try {
      const {
        data: { data: userData },
      } = await HTTPClient.post("/auth/mallTeam", {
        ...data,
        ...constVariable,
      });
      console.log(userData);
      return userData;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async forgotPass_sendEmail(email) {
    try {
      const response = await HTTPClient.post("/auth/forgotPassword", { email });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async ResetPassword(payload) {
    try {
      const response = await HTTPClient.post("/auth/resetPassword", {
        ...payload,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async registerUser(payload) {
    try {
      const response = await HTTPClient.post("/auth/verifyUser", {
        ...payload,
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateUser(payload) {
    try {
      let result = HTTPClient.customRequest({
        url: `/profile/updateUser`,
        method: "put",
        headers: { "Content-Type": "multipart/form-data" },
        data: payload,
      });
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getUser(id) {
    try {
      let result = await HTTPClient.get(`/profile/${id}`);
      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async checkLinkExp(params) {
    try {
      const response = await HTTPClient.get("/auth/verifyCode/", params);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async verifyOtp() {
    try {
      let result = HTTPClient.customRequest({
        url: `/userRequestOtp/`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      return result;
    } catch (error) {
      let error_object = { error_type: "SERVER" };
      if (error.status === 422 && error.data.status.code === 422)
        error_object.errors = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400)
        error_object.errors = error.data.status.message;
      else error_object.errors = "UNKNOWN ERROR";
      throw error_object;
    }
  },
};
