import {HTTPClient} from './http.service';

export const shopAndWinService = {
  async createCampaign (data) {
    try {
      const result = await HTTPClient.post('/scanCampaign', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getList (data) {
    try {
      const result = await HTTPClient.get('/scanCampaign/', data);
      return result;
    } catch(error) {
      return error;
    }
  },
  async deleteCampaign (data) {
    try {
      const result = await HTTPClient.delete(`/scanCampaign/${data}`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getCampaign (data) {
    try {
      const result = await HTTPClient.get(`/scanCampaign/${data}`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async updateCampaign (data, id) {
    try {
      const result = await HTTPClient.put(`/scanCampaign/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getUsersOfACampaign (params, id) {
    try {
      const result = await HTTPClient.get(`/scanCampaign/users/${id}`, params);
      return result;
    } catch(error) {
      return error;
    }
  },
}
