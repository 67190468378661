import {HTTPClient} from './http.service';

export const physicalVoucherDistService = {
  // async tierList() {
  //   try {
  //     const result = await HTTPClient.get('/tiers');
  //     return result;
  //   } catch(error) {
  //     return error;
  //   }
  // },
  async scanQrCode (data) {
    try {
      const result = await HTTPClient.post('/handler/physicalVoucher/scanQrCode', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async verifyOTPForQrCode (data) {
    try {
      const result = await HTTPClient.put('/conciergePhysicalVoucher/verifyScanQrCode', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async updateCouponCode (data) {
    try {
      const result = await HTTPClient.put('/conciergePhysicalVoucher/updateVoucherCode', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async completeHandover (data) {
    try {
      const result = await HTTPClient.put('/conciergePhysicalVoucher/confirm', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getPhyVoucherDistCoupon(param) {
    try {
      const data = await HTTPClient.get('/conciergePhysicalVoucher/Coupon/', param);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getPhyVoucherDistReward(param) {
    try {
      const data = await HTTPClient.get('/conciergePhysicalVoucher/Reward/', param);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getDetails (id) {
    try {
      const data = await HTTPClient.get(`/conciergePhysicalVoucher/Coupon/${id}`);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getRewardDetails (id) {
    try {
      const data = await HTTPClient.get(`/conciergePhysicalVoucher/reward/${id}`);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  },
}
