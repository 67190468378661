import { takeLatest, put, all, call } from 'redux-saga/effects';
import { bookingManagementActionTypes } from './bookingManagement.actiontype';
import { bookingManagementService } from '../../service';
import { actions } from './bookingManagement.action';

function* handleGetBookingManagement ({data}) {
  try {
    yield put(actions.request())
    let result = yield bookingManagementService.getBookingManagementList(data)
    yield put(actions.getSlots(result.data.data))
    return result;
  } catch(error) {
    console.log(error);
  } finally {
    yield put(actions.complete())
  }
}

function* handleGetBookingManagementUsers ({data}) {
  try {
    yield put(actions.request())
    let result = yield bookingManagementService.getBookingManagementUsersList(data)
    yield put(actions.getUsers(result.data.data))
    return result;
  } catch(error) {
    console.log(error);
  } finally {
    yield put(actions.complete())
  }
}

export function* getBookingManagement() {
  yield takeLatest(bookingManagementActionTypes.WATCH_GET_BOOKING_MANAGEMENT, handleGetBookingManagement)
}

export function* getBookingManagementUsers() {
  yield takeLatest(bookingManagementActionTypes.WATCH_GET_BOOKING_MANAGEMENT_USERS, handleGetBookingManagementUsers)
}

export function* BookingManagementSaga() {
  yield all([
    call(getBookingManagement),
    call(getBookingManagementUsers)
  ]);
}