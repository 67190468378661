
import {categoryActionTypes} from './category.actiontype';

export const actions = {
  request: () => ({ type: categoryActionTypes.REQUEST }),
  complete: () => ({ type: categoryActionTypes.COMPLETE }),

  addRequest: () => ({type: categoryActionTypes.ADD_REQUEST}),
  addComplete: () => ({type: categoryActionTypes.ADD_COMPLETE}),
  addSuccess: (data) => ({type: categoryActionTypes.ADD_SUCCESS, data}),

  getCategories: data => ({ type: categoryActionTypes.GET_CATEGORIES, data }),
  getCategoryById: data => ({ type: categoryActionTypes.GET_CATEGORY_BY_ID, data }),
  deleteCategory: id => ({ type: categoryActionTypes.DELETE_CATEGORY_BY_ID, data: id }),
  updateCategory: data => ({ type: categoryActionTypes.UPDATE_CATEGORY, data: data }),

  setError: data => ({ type: categoryActionTypes.SET_ERROR, data }),
};