
import {shopNumberActionTypes} from './shopNumber.actiontype';

const initalState = {
  loading: false,
  add_loading: false,
  server_error: '',
  server_success: '',
  shop_numbers: [],
  shop_numbers_key_value: [],
  total_shop_numbers: null,
  shop_number: {},
  showModal: false
};

export const RetailerShopNumber  = (state = initalState, action = {}) => {

  switch(action.type){

    case shopNumberActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case shopNumberActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case shopNumberActionTypes.ADD_REQUEST:
      return {
        ...state,
        add_loading: true
      };

    case shopNumberActionTypes.ADD_COMPLETE:
      return {
        ...state,
        add_loading: false
      };

    case shopNumberActionTypes.ADD_SUCCESS:
      return {
        ...state,
        add_loading: false,
        showModal: false,
        server_success: action.data
      }

    case shopNumberActionTypes.ADD_FAILED:
      return {
        ...state,
        server_error: action.data,
        server_success: ''
      }

    case shopNumberActionTypes.EDIT_SUCCESS:
      return {
        ...state,
        server_error: '',
        server_success: action.data
      }

    case shopNumberActionTypes.CLEAR_INITIAL:
      return {
        ...state,
        server_error: '',
        server_success: ''
      }

    //apis
    case shopNumberActionTypes.GET_SHOP_NUMBERS:
      return {
        ...state,
        shop_numbers: action.data.docs,
        shop_numbers_key_value: action.data.docs.map((shop) =>  {
          return {
            value: shop.id,
            label: shop.shopNumber,
            shop_IID: shop.shopIID ? shop.shopIID : '',
            floor_number: shop.floorNumber,
            shop_area: shop.shopArea,
          }
        }),
        total_shop_numbers: action.data.totalEntries
      }

    case shopNumberActionTypes.GET_SHOP_NUMBER_BY_ID:
      return {
        ...state,
        shop_number: action.data,
        showModal: true
      }

    case shopNumberActionTypes.SET_SHOP_NUMBER_MODAL_SHOW:
      return {
        ...state,
        showModal: action.data
      }


    case shopNumberActionTypes.DELETE_SHOP_NUMBER_BY_ID:
      return {
        ...state,
        shop_numbers: state.shop_numbers.filter(shop_number => shop_number.id !== action.data)
      };

    case shopNumberActionTypes.UPDATE_SHOP_NUMBER:
      return {
        ...state,
        shop_numbers: state.shop_numbers.map(shop_number => {
          if (shop_number.id === action.data.id) {
            shop_number.shopNumber = action.data.shop_number
            shop_number.floorNumber = action.data.floor_number
            shop_number.shopArea = action.data.shop_area
            shop_number.tdrCategory = action.data.TDR_category
            return shop_number
          } else {
            return shop_number
          }
        }),
        showModal: false,
        shop_number: {}
      };

    case shopNumberActionTypes.CLEAR_SHOP_NUMBERS:
      return {
        ...state,
        shop_numbers: [],
        showModal: false,
        shop_number: {}
      }

    case shopNumberActionTypes.SET_ERROR:
      return {
        ...state,
        server_error: action.data,
        server_success: ''
      }


    default:
      return state;
  }
}
