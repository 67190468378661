
import {couponReportActionTypes} from './coupon.actiontype';

const initalState = {
  pageInfo:{
    pageSize: 10,
    pageNo: 1
  },
}

export const CouponReport  = (state = initalState, action = {}) => {
  switch(action.type){
    case couponReportActionTypes.COUPON_REPORT_SET_PAGE:
      return Object.assign({}, state, {
        ...state,
        [action.data.name]: {
          ...state[action.data.name],
          pageSize: action.data.value.pageSize,
          pageNo: action.data.value.pageNo
        }
      });

    case couponReportActionTypes.COUPON_REPORT_SET_INIT:
      return initalState;
    default:
      return state;
  }
}