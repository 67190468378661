
import {bookingManagementActionTypes} from './bookingManagement.actiontype';

const initalState = {
  loading: false,
  slots: [],
  total_slots: [],
  users: [],
  total_users: [],
  cancelDetails: {
    cancelReason: null,
    isCancelled: null
  }
}

export const BookingManagement  = (state = initalState, action = {}) => {
  switch(action.type){

    case bookingManagementActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case bookingManagementActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case bookingManagementActionTypes.GET_SLOTS:
      return {
        ...state,
        slots: action.data.docs,
        total_slots: action.data.totalEntries
      }

    case bookingManagementActionTypes.GET_USERS:
      return {
        ...state,
        users: action.data.docs,
        total_users: action.data.totalEntries,
        cancelDetails: {
          cancelReason: action.data.cancelDetails.cancelReason,
          isCancelled: action.data.cancelDetails.isCancelled,
        }
      }

    default:
      return state;
  }
}