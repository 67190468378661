import {HTTPClient} from './http.service';

export const handlerAppService = {
  async getHanlderApp (param) {
    try {
      const data = await HTTPClient.get('/handler/getBuildList', param);
      return data;
    }catch(e) {
      return Promise.reject(e)
    }
  }
}