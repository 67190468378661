import {createSelector} from 'reselect';

export const modulesAndMalls = state => state.modulesAndMalls;

export const appMenu = createSelector(
  [modulesAndMalls],
  modulesAndMalls => modulesAndMalls.menuObj
);

export const appModules = createSelector(
  [modulesAndMalls],
  modulesAndMalls => modulesAndMalls.modulesList
)