import {HTTPClient} from './http.service';

export const otherIssuesService = {

    async addIssue (data) {
      try {
        const result = await HTTPClient.post('/conciergeIssue/', data);
        return result;
      } catch(error) {
        return error;
      }
    },

    async getIssueList(data) {
        try {
          const result = await HTTPClient.get('/conciergeIssue/', data);
          return result;
        } catch(error) {
          return error;
        }
    },
    async getIssueDetail(id) {
      try {
        const {data:{data: issueDetails}} = await HTTPClient.get(`/conciergeIssue/${id}`);
        return issueDetails;
      } catch(e) {
        return Promise.reject(e)
      }
    },
}
