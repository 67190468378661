export const shopNumberActionTypes = {
  WATCH_ADD_RETAILER_SHOP_NUMBER: 'WATCH_ADD_RETAILER_SHOP_NUMBER',
  WATCH_GET_RETAILER_SHOP_NUMBER: 'WATCH_GET_RETAILER_SHOP_NUMBER',
  WATCH_CLEAR_SHOP_NUMBERS: 'WATCH_CLEAR_SHOP_NUMBERS',
  WATCH_GET_RETAILER_SHOP_NUMBER_BY_ID: 'WATCH_GET_RETAILER_SHOP_NUMBER_BY_ID',
  WATCH_UPDATE_RETAILER_SHOP_NUMBER: 'WATCH_UPDATE_RETAILER_SHOP_NUMBER',
  WATCH_DELETE_RETAILER_SHOP_NUMBER: 'WATCH_DELETE_RETAILER_SHOP_NUMBER',

  GET_SHOP_NUMBERS: 'GET_SHOP_NUMBERS',
  CLEAR_SHOP_NUMBERS: 'CLEAR_SHOP_NUMBERS',
  GET_SHOP_NUMBER_BY_ID: 'GET_SHOP_NUMBER_BY_ID',
  DELETE_SHOP_NUMBER_BY_ID: 'DELETE_SHOP_NUMBER_BY_ID',
  UPDATE_SHOP_NUMBER: 'UPDATE_SHOP_NUMBER',

  SET_SHOP_NUMBER_MODAL_SHOW: 'SET_SHOP_NUMBER_MODAL_SHOW',


  ADD_REQUEST: 'ADD_REQUEST',
  ADD_COMPLETE: 'ADD_COMPLETE',

  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',
  ADD_FAILED: 'ADD_FAILED',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  CLEAR_INITIAL: 'CLEAR_INITIAL',


  SET_ERROR: 'SET_ERROR',
  ADD_SUCCESS: 'ADD_SUCCESS'
};