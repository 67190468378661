import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { change_pageHeading } from "../store/appStore/appStore.action";
import { checkModuleCRUDstatus } from "../store/modules_mallsStore/mm.actions";
import { PageName } from "../utils/pageTitles";
import withAllowed from "../utils/withAllowed";
const CouponRedemptionReport = lazy(() =>
  import("./Reports/CouponRedemptionReportFolder/index")
);
const RewardRedemptionReport = lazy(() =>
  import("./Reports/RewardRedemptionReportFolder/index")
);
const ContactlessPaymentLogReport = lazy(() =>
  import("./Reports/ContactLessPaymentLogReport")
);
const ScanReports = lazy(() => import("./Reports/ScanReport"));
const ReportList = lazy(() => import("./Reports/ReportList"));



const ContentRoutes = ({ changePageHeading, match, ...props }) => {
  const {path, url} = match;
  const renderComponentFP = (
    Component,
    pageTitle = { title: "", moduleKey: "" }
  ) => {
    return (props) => {
      // changePageHeading(pageTitle.title);
      // checkModuleCRUDstatus(pageTitle.moduleKey);
      return <Component {...props} />;
    };
  };
  return <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from={url} to={`${url}/coupon-redemption`} />
        }

        <Route
          path="/reports/coupon-redemption"
          render={(props) =>
            renderComponentFP(
              withAllowed(CouponRedemptionReport),
              PageName.COUPON_REDEMPTION_REPORT
            )(props)
          }
        />

        <Route
          path="/reports/reward-redemption"
          render={(props) =>
            renderComponentFP(
              withAllowed(RewardRedemptionReport),
              PageName.REWARD_REDEMPTION_REPORT
            )(props)
          }
        />

        <Route
          path="/reports/scan-reports"
          render={(props) =>
            renderComponentFP(
              withAllowed(ScanReports),
              PageName.SCAN_REPORT
            )(props)
          }
        />

        <Route
          path="/reports/contactless-payment-log-report"
          render={(props) =>
            renderComponentFP(
              withAllowed(ContactlessPaymentLogReport),
              PageName.CONTACTLESS_PAYMENT_REPORT
            )(props)
          }
        />

        <Route
          path="/reports/report-list"
          render={(props) =>
            renderComponentFP(
              withAllowed(ReportList),
              PageName.REPORTSMANAGEMENT
            )(props)
          }
        />
    </Switch>

};

const mapStateToProps = ({ appData }) => ({
  mallModalOpen: appData.mallSwitchOpen,
});
const mapDisptachToProps = (dispatch) => ({
  changePageHeading: (pageHeading) => dispatch(change_pageHeading(pageHeading)),
  checkModuleCRUDstatus: (moduleName) =>
    dispatch(checkModuleCRUDstatus(moduleName)),
});
export default connect(mapStateToProps, mapDisptachToProps)(ContentRoutes);
