import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {appStoreActiontype} from './appStore.actiontype';

const INIT_STATE = {
  globalConstants:undefined,
  tier: undefined,
  apiErrors: undefined,
  toastObj: undefined,
  appApiProcess: false,
  pageHeading: '',
  breadcrumTitle: null
}


const appDataReducer =persistReducer(
  {storage, key: 'userModule', whitelist: ['modulesPremission']},
  (state= INIT_STATE, {type, payload}) => {
  switch(type) {
    case appStoreActiontype.APPCONSTANT_SUCCESS:
      return Object.assign({}, state, {
        globalConstants : payload
      });
    case appStoreActiontype.PAGEHEADING_CHANGE:
      return {
        ...state,
        pageHeading: payload
      };
    case appStoreActiontype.APPSTORE_ERROR:
      return {
        ...state,
        apiErrors: payload
      }
    case appStoreActiontype.APPSTORE_CLEAR_ERROR:
      return {
        ...state,
        apiErrors: undefined
      }
    case appStoreActiontype.SHOW_TOAST:
      return {
        ...state,
        toastObj: payload
      }
    case appStoreActiontype.CLEAR_TOAST:
      return {
        ...state,
        toastObj: undefined
      }
    case appStoreActiontype.TIER_DATA:
      return {
        ...state,
        tier: payload
      }
    case appStoreActiontype.APPCONSTANT_ONLOGOUT:
      return INIT_STATE

    case appStoreActiontype.DISPLAY_IN_BREADCRUM:
      return {
        ...state,
        breadcrumTitle: payload
      }
    default:
      return state
  }
}
)

export default appDataReducer;