import {HTTPClient} from './http.service';

export const customerManagement = {
    async getCustomerList(params) {
		try {
			let result = await HTTPClient.get(`/customers/`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async getCustomerDetails(customerId) {
		try {
			let result = await HTTPClient.get(`/customers/${customerId}`,);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async getCustomerIssues(userName,params) {
		try {
			let result = await HTTPClient.get(`/customers/issues/${userName}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async updateCustomerExclusive(customerId) {
		try {
			let result = await HTTPClient.put(`/customers/exclusive/${customerId}`,);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async updateCustomerBlocked(customerId) {
		try {
			let result = await HTTPClient.put(`/customers/block/${customerId}`,);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async updateCustomerActive(customerId) {
		try {
			let result = await HTTPClient.put(`/customers/active/${customerId}`,);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async transactionDetails(customerId) {
		try {
			let result = await HTTPClient.get(`/customers/transactions/${customerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async couponListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/coupons/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async scanListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/scans/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async rewardListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/rewards/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async activitieCount(customerId) {
		try {
			let result = await HTTPClient.get(`/customers/activities/${customerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async transactionListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/getCustomerTransactionList/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async gemsPassbook(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/gems/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async pointsPassbook(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/points/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async xpPassbook(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/xp/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
	async personalizedVisit(customerId, params) {
		try {
			let result = await HTTPClient.get(`/personalizedMallVisit/getCustomerSets/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },



    async utilization(customerId) {
		try {
			let result = await HTTPClient.get(`/customers/getCustomerUtilization/${customerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async loyaltyGraph(customerId) {
		try {
			let result = await HTTPClient.get(`/customers/customerLoyaltyGraph/${customerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
    },
    async scanGraph(customerId) {
		try {
			let result = await HTTPClient.get(`/customers/customerScanGraph/${customerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async customerUpdateComment(customerId, data) {
		try {
			let result = await HTTPClient.put(`/customers/update/${customerId}`, data);
			return result;
		} catch (error) {
			return error;
		}
	},
	async deleteFCMToken(number, fcmId) {
		try {
		  const response = await HTTPClient.delete(`/customers/fcm/${number}/${fcmId}`);
		  return response;
		} catch(e) {
		  return Promise.reject(e)
		}
	},
	async dineoutListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/dineout/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async mallVisitListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/mallVisit/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async parkingListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/parking/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async cardListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/scratchCard/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},

	async scanCountListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/scanCampaign/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async ruleListing(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/rule/${customerId}`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},

	async totalCouponsCount(customerId, params) {
		try {
			let result = await HTTPClient.get(`/customers/coupons/${customerId}`, params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},

	async getcustomerPointsGraphData(customerId) {
		try {
			let result = await HTTPClient.get(`/customers/customerPointsGraph/${customerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	}
}
