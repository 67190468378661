import {HTTPClient} from './http.service';

export const appService = {
  async getAppConstant() {
    try {
      const {data:{data: appConstant}} = await HTTPClient.get(`${process.env.REACT_APP_CONST_URL}/constants/getConstants`);
      return appConstant;
    }catch(e) {
      console.log(e);
    }
  },
  async getMenuObj () {
    try {
      const {data:{data: menuObj}} = await HTTPClient.get('/modules/getMenuObject');
      return menuObj;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async getModulesList () {
    try {
      const {data:{data: allModules}} = await HTTPClient.get('/modules/getModules');
      return allModules;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
  async getMallsList () {
    try {
      const {data:{data: docs}} = await HTTPClient.get('/mall/');
      return docs;
    }catch(e) {
      console.log(e.data.status);
      return Promise.reject(e.data.status);
    }
  },
}
