import {bulkActionTypes} from './bulk.actionType';

const initialState = {
  getList: null,
  selectedVouchersRewards: [],
  vouchersCount: null,
  rewardsCount: null
}

export const BulkVouchersRewards = (state = initialState, action) => {
  switch(action.type){
    case bulkActionTypes.GET_VOUCHER_DETAILS:
      return {
        ...state,
        getList: action.data
      }
    case bulkActionTypes.SELECTED_VOUCHERS_REWARDS:
      return {
        ...state,
        selectedVouchersRewards: [...state.selectedVouchersRewards, action.data]
      }
    case bulkActionTypes.REMOVE_VOUCHERS_REWARDS:
      return {
        ...state,
        selectedVouchersRewards: state.selectedVouchersRewards.filter((obj) => obj.id !== action.data.id)
      }
    case bulkActionTypes.VOUCHERS_COUNT:
      return {
        ...state,
        vouchersCount: (state.selectedVouchersRewards.filter((obj) => obj.voucherCategory === 1)).length
      }
    case bulkActionTypes.REWARDS_COUNT:
      return {
        ...state,
        rewardsCount: (state.selectedVouchersRewards.filter((obj) => obj.voucherCategory === 2)).length
      }
    default:
      return state;
  }
}

