export const PageName = {
  NOTIFICATIONCENTER: {title: 'Notification Management', moduleKey: 'notificationCenter'},
  USERMANAGEMENT: {title: 'User Management', moduleKey: 'userManagement'},
  COUPONLITE: {title: 'Coupon Management Lite', moduleKey: 'couponManagement'},
  COUPONPRO: {title: 'Coupon Management', moduleKey: 'couponManagementPro'},
  SCRATCHCARDMANAGEMENT: {title: 'Scratch Card Management', moduleKey: 'scratchCardManagement'},
  SCANRECONCILE: {title: 'Scan Reconcilation', moduleKey: 'scnaReconcile'},
  REFUTEMANAGEMENT: {title: 'Refute Management', moduleKey: 'refuteManagement'},
  SHOPANDWIN: {title: 'Shop and Win', moduleKey: 'scanCampaign'},
  RETAILER: {title: 'Retailer Management', moduleKey: 'retailers'},
  CUSTOMERS: {title: 'Customers', moduleKey: 'customers'},
  BOOKINGMANAGEMENT: {title: 'Plan Visit Booking', moduleKey: 'visitorBookingReport'},
  BANNERS: {title: 'Banner Management', moduleKey: 'banner'},
  ONBORADINGSCREEN: {title: 'Onboarding Screens', moduleKey: 'onboardingScreen'},
  MALLFEEDMANAGER: {title: 'Mall Feed Management', moduleKey: 'mallFeed'},
  RULEENGINER: {title: 'Rules Engine', moduleKey: 'rule'},
  REWARDMANAGEMENT: {title: 'Reward Management', moduleKey: 'rewardManagement'},
  PARKING: {title: 'Parking Management', moduleKey: 'parking'},
  BAGGAGESERVICES: {title: 'Baggage Services', moduleKey: 'baggage'},
  DINEOUT: {title: 'Dineout Orders', moduleKey: 'dineout'},
  // REFFERAL: {title: 'Collateral Referral Code Manager', moduleKey: 'referralCampaign'},
  REFFERAL: {title: 'Referral Code Management', moduleKey: 'referralCampaign'},
  CUSTOMERDELIGHT: {title: 'Delight Transfer', moduleKey: 'customerDelight'},

  COUPON_REDEMPTION_REPORT: {title: 'Coupon Redemption', moduleKey: 'couponRedemptionReport'},
  REWARD_REDEMPTION_REPORT: {title: 'Reward Redemption Report', moduleKey: 'rewardRedemptionReport'},
  SCAN_REPORT: {title: 'Scan Report', moduleKey: 'customerScansReport'},
  CONTACTLESS_PAYMENT_REPORT: {title: 'Contactless Payment Report', moduleKey: 'paymentTransaction'},
  FAVORITESTOREREPORT: {title: 'Favourite Store Report', moduleKey: 'favouriteStoreReport'},
  REPORTSMANAGEMENT: {title: 'Report Management', moduleKey: 'reportsManagement'},
  CUSTOMERBYDOB: {title: 'Customers By DOB', moduleKey: 'customerByDob'},
  CUSTOMERBYANIVVERSARY: {title: 'Customers BY Anniversary', moduleKey: 'customerByAnniversary'},

  ISSUEMANAGEMENT: {title: 'Issue Management', moduleKey: 'issueManagement'},
  DEEPLINK: {title: 'Deep Link Management', moduleKey: 'deepLink'},
  FOOTFALLREPORT: {title: 'Footfall Report', moduleKey: 'footfallReport'},
  SPINWHEEL: {title: 'Spin the Wheel', moduleKey: 'spinWheel'},
  PERSONALISEDMALLVISIT: {title: 'Personalised Mall Visit', moduleKey: 'personalizedMallVisit'},
  HANDERLARAPP: {title: 'Handler Apps', moduleKey: 'handlerApp'},
  PHYSICALVOUCHERDISTRIBUTION: {title: 'Physical Coupon Distribution', moduleKey: 'conciergePhysicalVoucher'},
  PHYSICALREWARDISTRIBUTION: {title: 'Physical Reward Distribution', moduleKey: 'conciergePhysicalVoucher'},
  OTHERISSUES: {title: 'Other Issues', moduleKey: 'otherIssues'},
  SCANSERVICE: {title: 'Scan Service', moduleKey: 'scanService'},
  VOUCHERMANAGEMENTBACKEND: {title: 'Voucher Management', moduleKey: 'voucherManagement'},
  SURVEY: {title: 'Survey Management', moduleKey: 'survey'},
  COHORTS: {title:'Cohort Management', moduleKey: 'cohorts'},
  BULK_REWARD_TRANSFER : {title:'Bulk transfer', moduleKey:'bulk'},
  STATIC_PAGES : {title:'Static Pages', moduleKey:'static'},
}
