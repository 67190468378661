import {HTTPClient} from './http.service';

export const auditLogsService = {
    async getAuditLogs(params) {
        try {
          const result = await HTTPClient.get(`${process.env.REACT_APP_CONST_URL}/auditLogs/getList`, params);
          return result;
        } catch(error) {
          return error;
        }
    },
}
