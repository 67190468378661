import {HTTPClient} from './http.service';
var qs = require('qs');

export const deepLinkService = {
    async addDeepLink(data) {
        try {
          let result = HTTPClient.customRequest({
              url: `/deepLink`,
              method: 'post',
              headers: {'Content-Type': 'application/x-www-form-urlencoded'},
              data: qs.stringify(data)
          });
          return result;
      } catch (error) {
          let error_object = { error_type: 'SERVER' };
          if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
          else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
          else error_object.errors = 'UNKNOWN ERROR'
          throw error_object;
      }
    },

    async getDeepLinks(data) {
        try {
          const result = await HTTPClient.get('/deepLink/', data);
          return result;
        } catch(error) {
          return error;
        }
    },

    async getSingleDeepLinkDetails(id) {
        try {
          const {data:{data: deepLinkDetails}} = await HTTPClient.get(`/deepLink/${id}`);
          return deepLinkDetails;
        } catch(e) {
          return Promise.reject(e)
        }
    },
} 