export const notificationActionTypes = {
  WATCH_GET_COHORT: 'WATCH_GET_COHORT',
  WATCH_GET_TIERS: 'WATCH_GET_TIERS',
  WATCH_ADD_NOTIFICATION: 'WATCH_ADD_NOTIFICATION',
  WATCH_GET_NOTIFICATIONS: 'WATCH_GET_NOTIFICATIONS',
  WATCH_DELETE_NOTIFICATIONS: 'WATCH_DELETE_NOTIFICATIONS',
  WATCH_GET_NOTIFICATION_BY_ID: 'WATCH_GET_NOTIFICATION_BY_ID',
  WATCH_EDIT_NOTIFICATION: 'WATCH_EDIT_NOTIFICATION',
  WATCH_REDIRECTION_TYPE_LIST: 'WATCH_REDIRECTION_TYPE_LIST',

  GET_COHORTS: 'GET_COHORTS',
  GET_TIERS: 'GET_TIERS',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  DELETE_NOTIFICATIONS: 'DELETE_NOTIFICATIONS',

  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',
  FAILED_ADD_NOTIFICATION: 'FAILED_ADD_NOTIFICATION',

  EDIT_SUCCESS: 'EDIT_SUCCESS',

  CLEAR_INITIAL: 'CLEAR_INITIAL',

  GET_REDIRECITON_TYPE_LIST: 'GET_REDIRECITON_TYPE_LIST'
};