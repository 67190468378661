import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { change_pageHeading } from "../store/appStore/appStore.action";
import { checkModuleCRUDstatus } from "../store/modules_mallsStore/mm.actions";
import { PageName } from "../utils/pageTitles";
import withAllowed from "../utils/withAllowed";
const DeepLink = lazy(() => import("./DeepLink/index"));
const Cohorts = lazy(() => import("./Cohorts"));
const Survey = lazy(() => import("./Survey"));
const Referral = lazy(() => import("./ReferralManagement/index"));
const Notification = lazy(() => import("./notification/index"));


const CommunicationRoutes = ({ changePageHeading, match, ...props }) => {
  const {path, url} = match;
  const renderComponentFP = (
    Component,
    pageTitle = { title: "", moduleKey: "" }
  ) => {
    return (props) => {
      changePageHeading(pageTitle.title);
      checkModuleCRUDstatus(pageTitle.moduleKey);
      return <Component {...props} />;
    };
  };
  return <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from={url} to={`${url}/notifications`} />
        }

        <Route
          path={`${url}/notifications`}
          render={(props) =>
            renderComponentFP(
              withAllowed(Notification),
              PageName.NOTIFICATIONCENTER
            )(props)
          }
        />

        <Route
          path={`${url}/deep-link-management`}
          render={(props) =>
            renderComponentFP(withAllowed(DeepLink), PageName.DEEPLINK)(props)
          }
        />

        <Route
          path="/communication/referral"
          render={(props) =>
            renderComponentFP(withAllowed(Referral), PageName.REFFERAL)(props)
          }
        />

        <Route
          path="/communication/survey"
          render={(props) => renderComponentFP(Survey, PageName.SURVEY)(props)}
        />

        <Route
          path="/communication/cohorts"
          render={(props) =>
            renderComponentFP(Cohorts, PageName.COHORTS)(props)
          }
        />
    </Switch>

};

const mapStateToProps = ({ appData }) => ({
  mallModalOpen: appData.mallSwitchOpen,
});
const mapDisptachToProps = (dispatch) => ({
  changePageHeading: (pageHeading) => dispatch(change_pageHeading(pageHeading)),
  checkModuleCRUDstatus: (moduleName) =>
    dispatch(checkModuleCRUDstatus(moduleName)),
});
export default connect(mapStateToProps, mapDisptachToProps)(CommunicationRoutes);
