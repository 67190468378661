import React from "react";

export const GalleryContext = React.createContext({
  image: "",
  setImage: () => {},
});

export const GalleryProvider = ({ children }) => {
  const [image, setImage] = React.useState("");

  return (
    <GalleryContext.Provider value={{ image, setImage }}>
      {children}
    </GalleryContext.Provider>
  );
};
