export const bookingManagementActionTypes = {
  WATCH_GET_BOOKING_MANAGEMENT: 'WATCH_GET_BOOKING_MANAGEMENT',
  WATCH_GET_BOOKING_MANAGEMENT_BY_Id: 'WATCH_GET_BOOKING_MANAGEMENT_BY_Id',
  WATCH_GET_BOOKING_MANAGEMENT_USERS: 'WATCH_GET_BOOKING_MANAGEMENT_USERS',

  REQUEST: 'REQUEST',
  COMPLETE: 'COMPLETE',

  GET_SLOTS: 'GET_SLOTS',
  GET_USERS: 'GET_USERS'
};