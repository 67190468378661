/* export const appStoreActiontype = {
  CONSTMODULES_INIT: 'CONSTMODULES_INIT', // TO GET ALL THE CONSTANTS AND MALL MODULES
  APPCONSTANT_SUCCESS: 'APPCONSTANT_SUCCESS', // ON SUCCESS OF APPCONSTANT
  MODULES_SUCCESS: 'MODULES_SUCCESS', // ON SUCCESS OF ALL THE MODULES RECEIVED
  MODULES_ALLOWED: 'MODULES_ALLOWED', // ON ALLOWED MODULES FOR A USER
  MALL_SWITCHTOGGLE: 'MALL_SWITCHTOGGLE',
  MALL_SWITCH: 'MALL_SWITCH',
  STORE_ONLOGOUT: 'STORE_ONLOGOUT', // REMOVE ALL THE MALL ON LOGOUT,
} */
export const appStoreActiontype = {
  APPCONSTANT_INIT:'APPCONSTANT_INIT',
  APPCONSTANT_SUCCESS: 'APPCONSTANT_SUCCESS',
  APPSTORE_ERROR: 'APPSTORE_ERROR',
  APPSTORE_CLEAR_ERROR: 'APPSTORE_CLEAR_ERROR',
  SHOW_TOAST: 'SHOW_TOAST',
  CLEAR_TOAST: 'CLEAR_TOAST',
  APPCONSTANT_ONLOGOUT: 'APPCONSTANT_ONLOGOUT' ,
  PAGEHEADING_CHANGE: 'PAGEHEADING_CHANGE',
  TIER_DATA: 'TIER_DATA',
  DISPLAY_IN_BREADCRUM: 'DISPLAY_IN_BREADCRUM'
}

/* 
use if it is required
  CONSTMODULES_SUCCESS: 'CONSTMODULES_SUCCESS',
 */