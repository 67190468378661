import {HTTPClient} from './http.service';

export const retailerCategoryService = {
  async addCategory(data) {
    try {
      const result = await HTTPClient.post('/retailers/category', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getCategories(data) {
    try {
      const result = await HTTPClient.get('/retailers/category/', data);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getCategoryById(id) {
    try {
      const result = await HTTPClient.get(`/retailers/category/${id}`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async updateCategory(data, id) {
    try {
      const result = await HTTPClient.put(`/retailers/category/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async deleteCategory(id) {
    try {
      const result = await HTTPClient.delete(`/retailers/category/${id}`);
      return result;
    } catch(error) {
      return error;
    }
  },

}