import {call, all} from 'redux-saga/effects';
import {authSaga} from './auth/auth.saga';
import {appStoreSaga} from './appStore/appStore.saga';
import {userSaga} from './userStore/user.saga';
import {notificationSaga} from './notification/notification.saga';
import {mmSaga} from './modules_mallsStore/mm.saga'

// retailers
import {retailerCategorySaga} from './retailer/category/category.saga';
import {retailerShopNumberSaga} from './retailer/shopNumber/shopNumber.saga';
import {retailerSaga} from './retailer/retailer.saga'
import {BookingManagementSaga} from './bookingManagement/bookingManagement.saga'

function* RootSaga() {
  yield all([
    call(authSaga),
    call(appStoreSaga),
    call(userSaga),
    call(mmSaga),
    call(notificationSaga),
    call(retailerCategorySaga),
    call(retailerShopNumberSaga),
    call(retailerSaga),
    call(BookingManagementSaga)
  ]);
}

export default RootSaga;