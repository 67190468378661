import {menuActionType} from './menu.actionType';
import menuConfigBase from '../../router/MenuConfig';

const INIT_STATE = {
  search:null,
  menuConfig:{items:[]},
}

const MenuSearch = (state= INIT_STATE, {type, data}) => {
  switch(type) {

    case menuActionType.MENU_SEARCH:
      // let menu = menuConfigBase.items.filter(item => {
      //   let searchStr = data.toLowerCase();
      //   let tempItem = item.title.toLowerCase().includes(searchStr);
      //   let tempSubmenu = item.submenu ? item.submenu.filter(ele => ele.title.toLowerCase().includes(searchStr)) : null
      //   if(tempItem){
      //     console.log(tempItem)
      //     return item;
      //   } else if(!tempItem && tempSubmenu !== null && tempSubmenu.length > 0 ){
      //     let obj = {
      //       ...item,
      //       submenu: tempSubmenu
      //     }
      //     console.log(obj)
      //     return obj;
      //   }
      // })
      // let menu = state.menuConfig.items.filter(
      //   item => item.title.toLowerCase().includes(data.toLowerCase()) || item.submenu.filter(ele => ele.title.toLowerCase().includes(data.toLowerCase()))
      // )
      // console.log(menu)
      return {
        ...state,
        menuConfig: {
          ...state.menuConfig,
          items: menuConfigBase.items.filter(
              item => item.title.toLowerCase().includes(data.toLowerCase()) || item.submenu.some(ele => ele.title.toLowerCase().includes(data.toLowerCase()))
            )
        }
      }

    case menuActionType.MENU_CONFIG:
      return Object.assign({}, state, {
        menuConfig : data
      });
          
        	
    default:
      return state
  }
}

export default MenuSearch;