import {mallFeedActionType} from './mallFeed.actionType';

const INIT_STATE = {
  mallFeedConstants:{},
  pageInfo: {
    pageSize: 10,
    pageNo: 1,
  }
}

const MallFeed = (state= INIT_STATE, {type, payload}) => {
  switch(type) {

    case mallFeedActionType.MALLFEED_CONSTANTS:
		return Object.assign({}, state, {
			  mallFeedConstants : payload
      });

    case mallFeedActionType.MALLFEED_SET_PAGE:
      return Object.assign({}, state, {
        ...state,
        pageInfo: {
          pageSize: payload.value.pageSize,
          pageNo: payload.value.pageNo
        }
      });

    case mallFeedActionType.MALLFEED_SET_INIT:
      return Object.assign({}, state, {
        ...state,
        pageInfo: {
          pageSize: 10,
          pageNo: 1,
        }
      });
        	
    default:
      return state
  }
}

export default MallFeed;