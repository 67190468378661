import {applyMiddleware, compose, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {persistStore} from 'redux-persist';
// import logger from 'redux-logger';
import {HTTPClient, BaggageHTTPClient, HTTPClientV2} from '../service';

import RootReducer from './root.reducer';
import RootSaga from './root.saga';

import {appConst_init} from './appStore/appStore.action';
import {modules_init} from './modules_mallsStore/mm.actions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

if(process.env.NODE_ENV === 'development') {
  // middleware.push(logger)
}

const store = createStore(
	RootReducer,
	composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store,{}, () => {
  const {auth, modulesAndMalls} = store.getState();
  if(auth.authToken) {
    HTTPClient.saveHeader({key: 'Authorization', value: `Bearer ${auth.authToken}`});
    HTTPClient.saveHeader({key: 'mallId', value: `mall_${modulesAndMalls.selectedMall}`})
    
    
    BaggageHTTPClient.saveHeader({key: 'Authorization', value: `Bearer ${auth.authToken}`});
    BaggageHTTPClient.saveHeader({key: 'mallId', value: `mall_${modulesAndMalls.selectedMall}`})

    HTTPClientV2.saveHeader({key: 'Authorization', value: `Bearer ${auth.authToken}`});
    HTTPClientV2.saveHeader({key: 'mallId', value: `mall_${modulesAndMalls.selectedMall}`})

    
    store.dispatch(appConst_init());
    store.dispatch(modules_init());
  }
});

sagaMiddleware.run(RootSaga);

export default store;