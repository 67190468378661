import {HTTPClient} from './http.service';

export const retailerService = {
  async addRetailer(data) {
    try {
      const result = await HTTPClient.post('/retailers/', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async updateRetailer (data, id) {
    try {
      const result = await HTTPClient.put(`/retailers/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getRetailers (data) {
    try {
      const result = await HTTPClient.get('/retailers/', data);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getRetailerById (id) {
    try {
      const result = await HTTPClient.get(`/retailers/${id}`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async updateRetailerMarketing (formData, id) {
    try {
      const result = await HTTPClient.put(`/retailers/marketingInfo/${id}`, formData)
      return result;
    } catch (error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async deleteMarketingImagePDFVideo (retailerId, id, body) {
    try {
      const result = await HTTPClient.deleteWithBody(`/retailers/deleteMarketingInfo/${retailerId}/${id}`, body)
      return result;
    } catch (error) {
      return error
    }
  },
  async updateCatalougeDataById (data, retailerId, id) {
    try {
      const result = await HTTPClient.put(`/retailers/updateCatalogPhoto/${retailerId}/${id}`, data)
      return result;
    } catch (error) {
      return error
    }
  },
  async getRetailerMarketingInfo (id) {
    try {
      const result = await HTTPClient.get(`/retailers/marketingInfo/${id}`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getRatings (parmas, id) {
    try {
      const result = await HTTPClient.get(`/retailers/ratings/${id}/`, parmas);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getRetailerSummary (id) {
    try {
      const result = await HTTPClient.get(`/retailers/retailerGraph/${id}/`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getSummary () {
    try {
      const result = await HTTPClient.get(`/retailers/retailerGraph/`);
      return result;
    } catch(error) {
      return error;
    }
  },
  async getFavouritesList (data) {
    try {
      const result = await HTTPClient.get(`/retailers/favorite`, data);
      return result;
    } catch(error) {
      return error;
    }
  },
  async requestForOTP (data) {
    try {
      const result = await HTTPClient.post(`/userRequestOtp/`, data);
      return result;
    } catch(error) {
      throw error;
    }
  },
  async deleteRetailer (id, otp) {
    try {
      const result = await HTTPClient.delete(`/retailers/${id}?verifyOtp=${otp}`);
      return result;
    } catch(error) {
      throw error;
    }
  }
}