import {HTTPClient} from './http.service';
var qs = require('qs');

export const contentService = {
    async getScreenList(params) {
		try {
			let result = await HTTPClient.get(`/landingScreen/`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async addScreen(data) {
		try {
			let result = HTTPClient.customRequest({
				url: `/landingScreen/`,
				method: 'post',
				headers: {'Content-Type': 'multipart/form-data'},
				data: data
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateScreen(data,screenId) {
		try {
			let result = HTTPClient.customRequest({
				url: `/landingScreen/${screenId}`,
				method: 'put',
				headers: {'Content-Type': 'multipart/form-data'},
				data: data
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getSingleScreen(screenId) {
		try {
			let result = await HTTPClient.get(`/landingScreen/${screenId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async deletScreen(screenId) {
		try {
			let result = await HTTPClient.delete(`/landingScreen/${screenId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async screenOrderList() {
		try {
			let result = await HTTPClient.get(`/landingScreen/count/`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateScreenStatus(status, screenId) {
		let data = { "status" : status}
		try {
			let result = HTTPClient.customRequest({
				url: `/landingScreen/${screenId}/updateStatus`,
				method: 'put',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			  });
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async getFeedList(params) {
		try {
			let result = await HTTPClient.get(`/newsFeed/`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
    async getTagList(params) {
		try {
			if(params === undefined){
				let result = await HTTPClient.get(`/newsFeed/tag?pageSize=100&pageNo=1&sort=desc&status=1`);
				return result;
			} else {
				let result = await HTTPClient.get(`/newsFeed/tag`,params);
				return result;
			}
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateTagStatus(status, tagId) {
		let data = { "status" : status}
		try {
			let result = HTTPClient.customRequest({
				url: `/newsFeed/tag/${tagId}/updateStatus`,
				method: 'put',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			  });
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateFeedStatus(status, tagId) {
		let data = { "status" : status}
		try {
			let result = HTTPClient.customRequest({
				url: `/newsFeed/${tagId}/updateStatus`,
				method: 'put',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			  });
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async deleteTag(tagId) {
		try {
			let result = await HTTPClient.delete(`/newsFeed/tag/${tagId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async deleteFeed(feedId) {
		try {
			let result = await HTTPClient.delete(`/newsFeed/${feedId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getSingleFeed(feedId) {
		try {
			let result = await HTTPClient.get(`/newsFeed/${feedId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getSingleTag(tagId) {
		try {
			let result = await HTTPClient.get(`/newsFeed/tag/${tagId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateFeed(data,feedId) {
		try {
			let result = HTTPClient.customRequest({
				url: `/newsFeed/${feedId}`,
				method: 'put',
				headers: {'Content-Type': 'multipart/form-data'},
				data: data
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateTag(data,tagId) {
		try {
			let result = HTTPClient.customRequest({
				url: `/newsFeed/tag/${tagId}`,
				method: 'put',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async addTag(data) {
		try {
			let result = HTTPClient.customRequest({
				url: `/newsFeed/tag/`,
				method: 'post',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async addFeed(data) {
		try {
			let result = HTTPClient.customRequest({
				url: `/newsFeed/`,
				method: 'post',
				headers: {'Content-Type': 'multipart/form-data'},
				data: data
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async retailersList(){
		try {
			let result = await HTTPClient.get(`/retailers/?pageNo=1&sort=desc&pageSize=1000`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async newsFeedConstants() {
		try {
			let result = await HTTPClient.get(`/newsFeed/constants/`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getBannerList(params) {
		try {
			let result = await HTTPClient.get(`/banner/`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getSingleBanner(bannerId) {
		try {
			let result = await HTTPClient.get(`/banner/${bannerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getBannerCount(params) {
		try {
			let result = await HTTPClient.get(`/banner/count/`,params);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async addBanner(data) {
		try {
			let result = HTTPClient.customRequest({
				url: `/banner/`,
				method: 'post',
				headers: {'Content-Type': 'multipart/form-data'},
				data: data
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateBanner(data,bannerId) {
		try {
			let result = HTTPClient.customRequest({
				url: `/banner/${bannerId}`,
				method: 'put',
				headers: {'Content-Type': 'multipart/form-data'},
				data: data
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async updateBannerStatus(status,bannerId) {
		let data = {"status": status}
		try {
			let result = HTTPClient.customRequest({
				url: `/banner/${bannerId}/updateStatus`,
				method: 'put',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: qs.stringify(data)
			});
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async deleteBanner(bannerId) {
		try {
			let result = await HTTPClient.delete(`/banner/${bannerId}`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async bannerRedirection() {
		try {
			let result = await HTTPClient.get(`/banner/redirectionType`);
			return result;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
	async getFeedUsers(params, id) {
		try {
			let {data: {data}} = await HTTPClient.get(`/newsFeed/activity/${id}/`,params);
			return data;
		} catch (error) {
			let error_object = { error_type: 'SERVER' };
			if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
			else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
			else error_object.errors = 'UNKNOWN ERROR'
			throw error_object;
		}
	},
}