import {userActiontype} from './user.actionType';

export const onUserLogin = (userData) => ({
  type: userActiontype.USER_PROFILE,
  payload: userData
});
export const onMallSwitch = (mallId) => ({
  type: userActiontype.USER_MALLSWITCH,
  payload: mallId
});
export const onUserLogout = () => ({
  type: userActiontype.USER_ONLOGOUT
});