import React, {useEffect, useState} from "react";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as queryString  from 'query-string';
import clsx from 'clsx';
import {register_start} from '../../store/auth/auth.action';
import {authService} from '../../service/api/auth.service';
// import * as auth from "../../store/ducks/auth.duck";
// import { register } from "../../crud/auth.crud";

function ResetPassword({match, location, history, registerUser}) {
  const {e: email, k: passCode} = queryString.parse(location.search);
  const [showPassword, changeShowPassowrd] = React.useState(false);
  const [showRePassword, changeReShowPassowrd] = React.useState(false);
  const [apiError, setApiError] = useState('');
  const [apiProcess, setApiProcess] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  useEffect(() => {
    if(!email && !passCode) {
      history.push('/auth')
    }
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="kt-login__body loginForm">
      <div className="kt-login__form">
        <div className="kt-login__title">
          <h3 className="font-size-h1 title">
            Reset Password
          </h3>
        </div>
        {
          apiError &&
          (
            <div role="alert" className="alert alert-danger">
              <div className="alert-text">{apiError}</div>
            </div>
          )
        }
        {
          apiSuccess ?
          (
            <div >
              <div role="alert" className="alert alert-info">
                <div className="alert-text">Congratulations , you have reseted the Password</div>
              </div>
              <div style={{textAlign: 'center'}}>
                  <Link
                    to="/auth/login"
                    className="kt-link kt-login__link-forgot"
                  >
                    Login
                  </Link>
              </div>
            </div>
          ) :
          (
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                password: "",
                confirmPassword: ""
              }}
              validate={values => {
                const errors = {};

                if (!values.password) {
                  errors.password = 'Password is a required field'
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = 'Confirm Password is a required field'
                } else if (values.password !== values.confirmPassword) {
                  errors.confirmPassword =
                    "Password and Confirm Password didn't match.";
                }
                return errors;
              }}
              onSubmit={async (values, { setStatus, setSubmitting }) => {
                setApiProcess(true);
                try {
                  await authService.ResetPassword({email, passCode, newPassword: values.password, confirmPassword: values.confirmPassword});
                  setApiSuccess(true);
                  setApiError('');
                }catch(error) {
                  const {data:{status:{message}}} = error
                  setApiError(message);
                }
                setApiProcess(false);
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group position-relative">
                    <div className={`password-eye-icon ${showPassword? 'active-icon': ''}`}>
                      {
                        !showPassword?
                        <i onClick = {() => changeShowPassowrd(true)} className="fa fa-eye-slash"></i> :
                        <i onClick = {() => changeShowPassowrd(false)} className="fa fa-eye"></i>
                      }
                    </div>
                    <input
                      type={showPassword? 'text': 'password'}
                      placeholder="New Password*"
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                    />
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.password}</div>
                    </div>
                  </div>

                  <div className="form-group position-relative">
                    <div className={`password-eye-icon ${showRePassword? 'active-icon': ''}`}>
                    {
                      !showRePassword?
                      <i onClick = {() => changeReShowPassowrd(true)} className="fa fa-eye-slash"></i> :
                      <i onClick = {() => changeReShowPassowrd(false)} className="fa fa-eye"></i>
                    }
                    </div>
                    <input
                      type={showRePassword? 'text': 'password'}
                      placeholder="Confirm Password*"
                      className={`form-control form-control-solid h-auto py-5 px-6`}
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.confirmPassword}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    />
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.confirmPassword}</div>
                    </div>
                  </div>

                  <div className="kt-login__actions centerActions">
                  <button
                      type="submit"
                      className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": apiProcess
                        }
                      )}`}
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          )
        }
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  registerUser: payload => dispatch(register_start(payload))
})
export default connect(null, mapDispatchToProps)(ResetPassword);
