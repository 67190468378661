import {takeLatest, call, all} from 'redux-saga/effects';
import {userActiontype} from './user.actionType';


export function* handleMallSwitch({payload: selectedMall}) {
  // yield console.log('selected mall', selectedMall);
  // yield HTTPClient.saveHeader({key: 'mallId', value: `mall_${selectedMall}`});
  // console.log('mall Switched');
}

export function* userMallSwitchInit() {
  yield takeLatest(userActiontype.USER_MALLSWITCH, handleMallSwitch)
}

export function* userSaga() {
  yield all([
    call(userMallSwitchInit)
  ])
}
