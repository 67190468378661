import { takeLatest, put, all, call } from 'redux-saga/effects';
import { retailerActionTypes } from './retailer.actiontype';
import { retailerService } from '../../service';
import { actions } from './retailer.action';

function* handleGetRetailers ({data}) {
  try {
    yield put(actions.request())
    let result = yield retailerService.getRetailers(data)
    yield put(actions.getRetailers(result.data.data))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(actions.complete())
  }
}

export function* getRetailers() {
  yield takeLatest(retailerActionTypes.WATCH_GET_RETAILERS, handleGetRetailers)
}

export function* retailerSaga() {
  yield all([
    call(getRetailers)
  ]);
}