import {HTTPClient} from './http.service';
var qs = require('qs');

export const conciergeScanService = {

    async addScan (data) {
      try {
        const result = await HTTPClient.post('/conciergeScan/', data);
        return result;
      } catch(error) {
        return Promise.reject(error)
      }
    },

    async getCustomerScans(data) {
        try {
          const result = await HTTPClient.get('/conciergeScan/', data);
          return result;
        } catch(error) {
          return error;
        }
    },

    async getScanDetail(id) {
      try {
        const {data:{data: scanDetails}} = await HTTPClient.get(`/conciergeScan/${id}`);
        return scanDetails;
      } catch(e) {
        return Promise.reject(e)
      }
    },
    
    async requestOTP (data) {
      try {
        let result = HTTPClient.customRequest({
            url: `/conciergeScan/verifyUser/requestOtp`,
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data)
        });
        return result;
      } catch (error) {
          let error_object = { error_type: 'SERVER' };
          if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
          else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
          else error_object.errors = 'UNKNOWN ERROR'
          throw error_object;
      }
    },

    async verifyOTP (data) {
      try {
        let result = HTTPClient.customRequest({
            url: `/conciergeScan/verifyUser/verifyOtp`,
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: qs.stringify(data)
        });
        return result;
      } catch (error) {
          let error_object = { error_type: 'SERVER' };
          if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
          else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
          else error_object.errors = 'UNKNOWN ERROR'
          throw error_object;
      }
    },
} 