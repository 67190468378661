import { HTTPClient } from './http.service';


export const dashboardService = {
  async getDashboardData(load, tab, dates) {
    try {
      const result = await HTTPClient.get(`/dashboardV2?load=${load}&tab=${tab}&startDate=${dates?.startDate}&endDate=${dates?.endDate}`);
      return result;
    } catch (error) {
      return error;
    }
  },
}