
import {notificationActionTypes} from './notification.actiontype';

const initalState = {
  tiers: [], 
  cohort: [],
  loading: false,
  server_error: '',
  server_success: '',
  notifications: [],
  total_notifications: null,
  redirection_types: []
}

export const Notification  = (state = initalState, action = {}) => {
  switch(action.type){

    case notificationActionTypes.GET_COHORTS:
      return {
        ...state,
        cohort: action.data.map(c => {
          return {
            value: c.id,
            label: c.cohortName
          }
        })
      }

    case notificationActionTypes.GET_TIERS:
      return {
        ...state,
        tiers: action.data.map(t => {
          return {
            value: t.id,
            label: t.tierName
          }
        })
      }

    case notificationActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };

    case notificationActionTypes.COMPLETE:
      return {
        ...state,
        loading: false
      };

    case notificationActionTypes.FAILED_ADD_NOTIFICATION:
      return {
        ...state,
        server_error: action.data,
        server_success: ''
      }

    case notificationActionTypes.EDIT_SUCCESS:
      return {
        ...state,
        server_error: '',
        server_success: action.data
      }

    case notificationActionTypes.CLEAR_INITIAL:
      return {
        ...state,
        server_error: '',
        server_success: ''
      }

    case notificationActionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.data.notifications,
        total_notifications: action.data.total
      }

    case notificationActionTypes.DELETE_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.id !== action.data),
        total_notifications: state.total_notifications - 1
      }

    case notificationActionTypes.GET_REDIRECITON_TYPE_LIST:
      return {
        ...state,
        redirection_types: action.data
      }

    default:
      return state;
  }
}