import {HTTPClient} from './http.service';
var qs = require('qs');

export const scratchCardService = {

  async getScratchCards(data) {
    try {
      const result = await HTTPClient.get('/scratchCard/', data);
      return result;
    } catch(error) {
      return error;
    }
  },

  async addScratchCard(data) {
  	try {
		let result = HTTPClient.customRequest({
			url: `/scratchCard`,
			method: 'post',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			data: qs.stringify(data)
		});
		return result;
	} catch (error) {
		let error_object = { error_type: 'SERVER' };
		if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
		else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
		else error_object.errors = 'UNKNOWN ERROR'
		throw error_object;
	}
  },

  async getSingleScratchCardDetails(id) {
    try {
      const {data:{data: scratchCardDetail}} = await HTTPClient.get(`/scratchCard/${id}`);
      return scratchCardDetail;
    } catch(e) {
      return Promise.reject(e)
    }
  },

  async updateScratchCard(id, data) {
  	try {
		let result = HTTPClient.customRequest({
			url: `/scratchCard/updateScratchCard/${id}`,
			method: 'put',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			data: qs.stringify(data)
		});
		return result;
	} catch (error) {
		let error_object = { error_type: 'SERVER' };
		if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
		else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
		else error_object.errors = 'UNKNOWN ERROR'
		throw error_object;
	}
  },

  async updateScratchCardStatus(id, data) {
	try {
	  let result = HTTPClient.customRequest({
		  url: `/scratchCard/${id}`,
		  method: 'put',
		  headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		  data: qs.stringify(data)
	  });
	  return result;
  } catch (error) {
	  let error_object = { error_type: 'SERVER' };
	  if (error.status === 422 && error.data.status.code === 422) error_object.errors = error.data.status.message;
	  else if (error.status === 400 && error.data.status.code === 400) error_object.errors = error.data.status.message;
	  else error_object.errors = 'UNKNOWN ERROR'
	  throw error_object;
  }
},

async getCardUsage(id, data) {
    try {
      const response = await HTTPClient.get(`/scratchCard/cardUsage/${id}`, data);
      return response;
    } catch(e) {
      return Promise.reject(e)
    }
  },

}