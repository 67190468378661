import {HTTPClient} from './http.service';


export const footfallDayService = {
    async getFootfallDay(params) {
        try {
          const result = await HTTPClient.get('/footfallDashboard/day', params);
          return result;
        } catch(error) {
          return error;
        }
    },
    async getFootfallMonth(month) {
      try {
        const result = await HTTPClient.get(`/footfallDashboard/month?month=${month}`);
        return result;
      } catch(error) {
        return error;
      }
  }
} 