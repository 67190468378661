import { HTTPClient } from "./http.service";
var qs = require("qs");
export const surveyService = {
  async createSurvey(data) {
    try {
      let result = HTTPClient.customRequest({
        url: `/survey/`,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
      });
      return result;
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async editSurvey(data, id) {
    try {
      let result = HTTPClient.customRequest({
        url: `/survey/${id}`,
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: data,
      });
      return result;
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getSurveyById(id) {
    try {
      let result = await HTTPClient.get(`/survey/getSingleSurvey/${id}`);
      return result.data.data;
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async getSurveyByStatus(params) {
    try {
      let { data } = await HTTPClient.get("/survey/getSurvey", params);
      return data;
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async deleteSurvey(id) {
    try {
      return await HTTPClient.put(`/survey/deleteSurvey/${id}`);
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async endSurvey(id) {
    try {
      return await HTTPClient.put(`/survey/endSurvey/${id}`);
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getSurveySummary(id) {
    try {
      return await HTTPClient.get(`/survey/reports/summary/${id}`);
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getQuestionAnswers(surveyId, questionId, raw = null) {
    try {
      let params = null;
      if (raw) params = { raw };
      return await HTTPClient.get(
        `/survey/reports/question/${surveyId}/${questionId}`,
        params
      );
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async getSurveyUsers(surveyId, isCompleted = 1) {
    try {
      return await HTTPClient.get(`/survey/userData/${surveyId}?isCompleted=${isCompleted}`);
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async getUserResponsesForSurvey(surveyId, userId) {
    try {
      return await HTTPClient.get(`/survey/response/${surveyId}/${userId}`);
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async getSurveyResponses(surveyId) {
    try {
      return await HTTPClient.get(`/survey/response/${surveyId}`);
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async getSurveyQuestions(surveyId) {
    try {
      return await HTTPClient.get(`/survey/questions/${surveyId}`);
    } catch (err) {
      return Promise.reject(err)
    }
  },
};
