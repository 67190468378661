import {HTTPClient} from './http.service';

export const retailerFloorService = {
  async addFloor(data) {
    try {
      const result = await HTTPClient.post('/retailers/floor/addFloor', data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async getFloors(data) {
    try {
      const result = await HTTPClient.get('/retailers/floor/floors', data);
      return result;
    } catch(error) {
      return error;
    }
  },
  async updateFloor(data, id) {
    try {
      const result = await HTTPClient.put(`/retailers/floor/${id}`, data);
      return result;
    } catch(error) {
      let error_object;
      if (error.status === 422 && error.data.status.code === 422) error_object = error.data.status.message;
      else if (error.status === 400 && error.data.status.code === 400) error_object = error.data.status.message;
      else error_object = 'UNKNOWN ERROR'
      throw error_object;
    }
  },
  async deleteFloor(id) {
    try {
      const result = await HTTPClient.delete(`/retailers/floor/${id}`);
      return result;
    } catch(error) {
      return error;
    }
  },
}